import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {Dayjs} from 'dayjs';
import Typography from '@mui/material/Typography';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {useCalendarCell} from '@Utils/hooks/useCalendarCell';
import {HotelNarrowDate} from './types';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import Button from '@mui/material/Button';
import {CalendarCellButton} from './CalendarComponents';
import CalendarCellDetails from './CalendarCellDetails';
import DefaultCalendarCell from './DefaultCalendarCell';

export type CellData = IDatesGroupedByDay | DayOffersProcessed | HotelNarrowDate;

type Props = {
  calendarDay?: Dayjs | null;
  cellData?: CellData;
  onClick?: (date: string) => void;
  isHeader?: boolean;
  showPrices?: boolean;
  showAvailableSeatsForDay?: boolean;
  lastDayInMonth?: number | null;
  isHotelNarrowDate?: boolean
}

const CalendarCell = ({
  calendarDay,
  cellData,
  children,
  onClick,
  isHeader,
  showPrices,
  showAvailableSeatsForDay,
  lastDayInMonth,
  isHotelNarrowDate
}: React.PropsWithChildren<Props>) => {
  if (isHeader) {
    return (
      <Typography
        variant="overline"
        sx={{
          width: 1,
          fontSize: '12px',
          textAlign: 'center',
          color: 'text.disabled'
        }}
      >
        {children}
      </Typography>
    );
  }

  if (!calendarDay) {
    return (
      <Button
        disabled
        // add a style to create a cell of the same size like another elements
        sx={{
          width: '100%',
          minWidth: 'auto',
          border: '1px solid #fff',
          padding: 0
        }}
      />
    );
  }

  const data = useCalendarCell({cellData, calendarDay, showPrices});
  const isDisabled = !!data?.isDisabled || !data;
  const showInformations = showAvailableSeatsForDay || showPrices;
  const isFirstDayInMonth = calendarDay.date() === 1;
  const isLastDayInMonth = calendarDay.date() === lastDayInMonth;

  return (
    <CalendarCellButton
      color="primary"
      disableFocusRipple
      disableRipple
      isSelected={data?.isSelected}
      isDisabled={isDisabled}
      isSelectedStart={data?.isSelectedStart}
      isSelectedMiddle={data?.isSelectedMiddle}
      isSelectedEnd={data?.isSelectedEnd}
      isFirstDayInMonth={isFirstDayInMonth}
      isLastDayInMonth={isLastDayInMonth}
      onClick={
        onClick &&
          (() =>
            onClick(data?.date || calendarDay.format(DASH_DAY_FORMAT)))
      }
    >
      {showInformations ? (
        <CalendarCellDetails
          calendarDay={calendarDay}
          isDisabled={isDisabled}
          data={data}
          showAvailableSeatsForDay={!!showAvailableSeatsForDay}
          showPrices={!!showPrices}
        />
      ) : (
        <DefaultCalendarCell
          calendarDay={calendarDay}
          isDisabled={isDisabled}
          data={data}
          isHotelNarrowDate={isHotelNarrowDate}
        />
      )}
    </CalendarCellButton>
  );

};

export default CalendarCell;
