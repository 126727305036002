import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '../../../../utils/locale';
import Select from '../../../common/form/Select';

export const PHONE_PREFIXES = [
  {countryCode: 'PL', value: '48', text: 'PL (+48)'},
  {countryCode: null, value: null, text: locale.translate('restCountries'), disabled: true},
  {countryCode: 'AE', value: '971', text: 'AE (+971)'},
  {countryCode: 'AR', value: '54', text: 'AR (+54)'},
  {countryCode: 'AU', value: '61', text: 'AU (+61)'},
  {countryCode: 'AT', value: '43', text: 'AT (+43)'},
  {countryCode: 'BA', value: '387', text: 'BA (+387)'},
  {countryCode: 'BE', value: '32', text: 'BE (+32)'},
  {countryCode: 'BG', value: '359', text: 'BG (+359)'},
  {countryCode: 'BR', value: '55', text: 'BR (+55)'},
  {countryCode: 'BY', value: '375', text: 'BY (+375)'},
  {countryCode: 'CA', value: '1', text: 'CA (+1)'},
  {countryCode: 'CH', value: '41', text: 'CH (+41)'},
  {countryCode: 'CL', value: '56', text: 'CL (+56)'},
  {countryCode: 'CN', value: '86', text: 'CN (+86)'},
  {countryCode: 'CO', value: '57', text: 'CO (+57)'},
  {countryCode: 'CS', value: '381', text: 'CS (+381)'},
  {countryCode: 'CZ', value: '420', text: 'CZ (+420)'},
  {countryCode: 'CY', value: '357', text: 'CY (+357)'},
  {countryCode: 'DE', value: '49', text: 'DE (+49)'},
  {countryCode: 'DK', value: '45', text: 'DK (+45)'},
  {countryCode: 'EE', value: '372', text: 'EE (+372)'},
  {countryCode: 'EG', value: '20', text: 'EG (+20)'},
  {countryCode: 'ES', value: '34', text: 'ES (+34)'},
  {countryCode: 'FI', value: '358', text: 'FI (+358)'},
  {countryCode: 'FR', value: '33', text: 'FR (+33)'},
  {countryCode: 'GB', value: '44', text: 'GB (+44)'},
  {countryCode: 'GI', value: '350', text: 'GI (+350)'},
  {countryCode: 'GR', value: '30', text: 'GR (+30)'},
  {countryCode: 'HK', value: '852', text: 'HK (+852)'},
  {countryCode: 'HR', value: '385', text: 'HR (+385)'},
  {countryCode: 'HU', value: '36', text: 'HU (+36)'},
  {countryCode: 'ID', value: '62', text: 'ID (+62)'},
  {countryCode: 'IE', value: '353', text: 'IE (+353)'},
  {countryCode: 'IL', value: '972', text: 'IL (+972)'},
  {countryCode: 'IN', value: '91', text: 'IN (+91)'},
  {countryCode: 'IS', value: '354', text: 'IS (+354)'},
  {countryCode: 'IT', value: '39', text: 'IT (+39)'},
  {countryCode: 'JP', value: '81', text: 'JP (+81)'},
  {countryCode: 'KR', value: '82', text: 'KR (+82)'},
  {countryCode: 'KW', value: '965', text: 'KW (+965)'},
  {countryCode: 'KZ', value: '7', text: 'KZ (+7)'},
  {countryCode: 'LI', value: '417', text: 'LI (+417)'},
  {countryCode: 'LT', value: '370', text: 'LT (+370)'},
  {countryCode: 'LU', value: '352', text: 'LU (+352)'},
  {countryCode: 'LV', value: '371', text: 'LV (+371)'},
  {countryCode: 'MC', value: '377', text: 'MC (+377)'},
  {countryCode: 'MD', value: '373', text: 'MD (+373)'},
  {countryCode: 'MK', value: '389', text: 'MK (+389)'},
  {countryCode: 'MT', value: '356', text: 'MT (+356)'},
  {countryCode: 'MX', value: '52', text: 'MX (+52)'},
  {countryCode: 'MY', value: '60', text: 'MY (+60)'},
  {countryCode: 'NL', value: '31', text: 'NL (+31)'},
  {countryCode: 'NO', value: '47', text: 'NO (+47)'},
  {countryCode: 'NZ', value: '64', text: 'NZ (+64)'},
  {countryCode: 'OM', value: '968', text: 'OM (+968)'},
  {countryCode: 'PA', value: '507', text: 'PA (+507)'},
  {countryCode: 'PE', value: '51', text: 'PE (+51)'},
  {countryCode: 'PH', value: '63', text: 'PH (+63)'},
  {countryCode: 'PT', value: '351', text: 'PT (+351)'},
  {countryCode: 'PY', value: '595', text: 'PY (+595)'},
  {countryCode: 'QA', value: '974', text: 'QA (+974)'},
  {countryCode: 'RO', value: '40', text: 'RO (+40)'},
  {countryCode: 'RU', value: '7', text: 'RU (+7)'},
  {countryCode: 'SA', value: '966', text: 'SA (+966)'},
  {countryCode: 'SE', value: '46', text: 'SE (+46)'},
  {countryCode: 'SG', value: '65', text: 'SG (+65)'},
  {countryCode: 'SI', value: '386', text: 'SI (+386)'},
  {countryCode: 'SK', value: '421', text: 'SK (+421)'},
  {countryCode: 'SM', value: '378', text: 'SM (+378)'},
  {countryCode: 'SZ', value: '268', text: 'SZ (+268)'},
  {countryCode: 'TR', value: '90', text: 'TR (+90)'},
  {countryCode: 'TW', value: '886', text: 'TW (+886)'},
  {countryCode: 'UA', value: '380', text: 'UA (+380)'},
  {countryCode: 'US', value: '1', text: 'US (+1)'},
  {countryCode: 'UY', value: '598', text: 'UY (+598)'},
  {countryCode: 'UZ', value: '998', text: 'UZ (+998)'},
  {countryCode: 'VA', value: '379', text: 'VA (+379)'},
  {countryCode: 'VN', value: '84', text: 'VN (+84)'},
  {countryCode: 'ZA', value: '27', text: 'ZA (+27)'}
];

const PhoneNumberPrefixes = ({value, onChange}) => (
  <Select
    name="phonePrefix"
    class="dl-dotpay__input-prefix-select"
    options={PHONE_PREFIXES}
    selected={value}
    onChange={onChange}
  />
);

export default PhoneNumberPrefixes;
