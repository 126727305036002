import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import TimePicker from '@Components/timePicker/TimePicker';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {EVENT_BUY_ADMISSION_DATES_SEES_HOURS, EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR} from '@Utils/events';
import {getDatesIdxWithMinPrice} from './admissionRealizationUtils';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getFormattedDate, getTimezoneDate, isSameDate} from '@Utils/dayjs/dayjsUtils';
import Box from '@mui/material/Box';
import MarkedHeader from '@Components/headers/MarkedHeader';

const AdmissionTimePicker = ({
  selectedTime,
  activeViewBasketItems,
  selectedDayDates,
  setError,
  sendAmplitudeEvent,
  admissionPolicy,
  showPrices,
  isSelectErrorComponent,
  handleRemoveSelectError
}) => {
  const {isOneAdmissionDateAvailable, firstAdmissionDate} = admissionPolicy;
  const timezone = useSelector(getFacilityTimezone);

  const handleSendEvent = () => {
    const dateAvailableHours = selectedDayDates[0].group.filter(({isOutOfLimit}) => !isOutOfLimit);

    sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SEES_HOURS, {
      numberOfAvailableHours: dateAvailableHours.length
    });
  };

  useEffect(() => {
    handleSendEvent();
  }, []);

  const dispatch = useDispatch();
  const {group: datesGroup, metaData} = selectedDayDates[0];
  const {canShowItemsLeft} = metaData;
  const datesGroupWithoutSoldOut = datesGroup.filter(({isOutOfLimit}) => !isOutOfLimit);
  const datesIdxWithMinPrice = getDatesIdxWithMinPrice(datesGroup);
  const datesWithGreenPrice = datesGroup.map((date, idx) => ({
    ...date,
    isBargainPrice: datesIdxWithMinPrice.includes(idx)
  }
  ));
  const timePickerOptions =
    datesGroupWithoutSoldOut.length !== datesIdxWithMinPrice.length ?
      datesWithGreenPrice : datesGroup;

  function handleSelectTime(selectedOption) {
    const {isOutOfLimit, numberOfSeats, date, description} = selectedOption;

    if (isOutOfLimit) {
      const time = getFormattedDate(getTimezoneDate(date, timezone), COLON_HOUR_FORMAT);

      dispatch(updateBasketItemsSelections(activeViewBasketItems, {time: null}));

      if (numberOfSeats) {
        const {resourceExists, variants} = selectedOption;
        const seats = resourceExists ? variants?.[0]?.resource?.amount : numberOfSeats;

        return setError(locale.translate('setDifferentHour', {time, numberOfSeats: seats}));
      }

      return setError(locale.translate('givenTimeIsSoldOut', {time}));
    }

    setError(null);

    // send event on select available time
    sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR, {
      admissionDateHour: getFormattedDate(getTimezoneDate(date, timezone), COLON_HOUR_FORMAT),
      admissionDateDescription: description
    });

    return dispatch(updateBasketItemsSelections(
      activeViewBasketItems,
      {time: selectedOption}
    ));
  }

  React.useEffect(() => {
    const firstDateTime = timePickerOptions[0];

    const isSameHourAsFirstAdmission = isSameDate(
      getTimezoneDate(firstAdmissionDate, timezone),
      getTimezoneDate(firstDateTime.originalDate, timezone),
      'hour'
    );

    if (isOneAdmissionDateAvailable && isSameHourAsFirstAdmission) {
      handleSelectTime(firstDateTime);
    }
  }, []);

  return (
    <Box sx={{mb: 2, mt: 3}} >
      <MarkedHeader text={locale.translate('chooseTime')} />
      <TimePicker
        options={timePickerOptions}
        value={selectedTime}
        onChange={handleSelectTime}
        canShowItemsLeft={canShowItemsLeft}
        showPrices={showPrices}
        isSelectErrorComponent={isSelectErrorComponent}
        handleRemoveSelectError={handleRemoveSelectError}
      />
    </Box>
  );
};

AdmissionTimePicker.propTypes = {
  activeViewBasketItems: PropTypes.array.isRequired,
  setError: PropTypes.func.isRequired,
  selectedDayDates: PropTypes.array,
  selectedTime: PropTypes.object
};

export default AdmissionTimePicker;
