import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import locale from '../../utils/locale';

export const DAY_NAMES = [
  'mondayShort',
  'tuesdayShort',
  'wednesdayShort',
  'thursdayShort',
  'fridayShort',
  'saturdayShort',
  'sundayShort'
];

const CalendarHeader = () => (
  <CalendarRow isHeader>
    {
      DAY_NAMES.map(dayName => (
        <CalendarCell
          key={dayName}
          isHeader
        >
          {locale.translate(dayName)}
        </CalendarCell>
      ))
    }
  </CalendarRow>
);

export default CalendarHeader;
