import React, {useEffect, useState} from 'react';
import CounterInput from './CounterInput';
import {isInteger} from '@Utils/typeGuards';
import {getDisableIncreasePickerReason, getInvalidFieldMessage, validateInputValue} from './utils';
import {getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {blockNextStepWith, unblockNextStepWith} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import {AddToBasketMethod} from '@Utils/eventsUtilsTypes';

type Props = {
  minValue: number;
  maxValue: number;
  disableReason: string;
  value: number;
  basketItemExists: boolean;
  isGroupVariantType: boolean;
  showErrorAbsolute?: boolean;
  variantId: number;
  onChange: (value: number, method?: AddToBasketMethod) => void;
  disabled: boolean;
};

const CounterPicker = ({
  minValue,
  maxValue,
  value,
  disableReason,
  basketItemExists,
  isGroupVariantType,
  showErrorAbsolute,
  variantId,
  onChange,
  disabled
}: Props) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<number | ''>(value);
  const [isInputActive, setIsInputActive] = useState<boolean>(false);
  const [currentInputMethod, setCurrentInputMethod] = useState<AddToBasketMethod>();
  const isInputValueValid = validateInputValue({
    inputValue,
    minValue,
    maxValue,
    isGroupVariantType,
    basketItemExists
  });

  const isMaxValueReached = isInteger(inputValue) && inputValue >= maxValue;
  const blockAddBasketItem = !basketItemExists && disableReason;
  const disableIncreasePickerReason = getDisableIncreasePickerReason({
    basketItemExists,
    disableReason,
    isGroupVariantType,
    isMaxValueReached
  });
  const invalidFieldMessage = getInvalidFieldMessage({
    maxValue: blockAddBasketItem ? 0 : maxValue,
    minValue,
    inputValue
  });
  const isNextStepBlocked = useSelector(getIsNextStepBlocked);
  const textFieldAriaLabel = isGroupVariantType ?
    locale.translate('numberOfParticipants') : locale.translate('numberOfActivities');

  const handleGroupInputValueChange = (inputValue: number) => {
    if (inputValue <= maxValue && !blockAddBasketItem) {
      onChange(inputValue, currentInputMethod);
    }
  };

  const handleInputValueChange = () => {
    // Handle empty input value
    if (!isInteger(inputValue)) {
      setInputValue(0);
      return onChange(0, currentInputMethod);
    }

    if (isGroupVariantType) {
      return handleGroupInputValueChange(inputValue);
    }

    if (isInputValueValid) {
      onChange(inputValue, currentInputMethod);
    }
  };

  const handleBlockNextStepWith = () => {
    if (!isInputValueValid) {
      dispatch(blockNextStepWith(variantId));
    }

    if (isNextStepBlocked && isInputValueValid) {
      dispatch(unblockNextStepWith(variantId));
    }
  };

  useEffect(() => {
    if (!isInputActive && inputValue !== value) {
      handleInputValueChange();
    }
  }, [inputValue, isInputActive]);

  useEffect(() => {
    handleBlockNextStepWith();
  }, [isInputValueValid]);

  const handleOnChange = (value: number | '') => {
    setInputValue(value);
    setCurrentInputMethod(AddToBasketMethod.INPUT);
  };

  const handleDecrease = () => {
    const decreasedValue = isInteger(inputValue) ? inputValue - 1 : 0;

    setInputValue(decreasedValue);
    setCurrentInputMethod(AddToBasketMethod.BUTTON);
  };

  const handleIncrease = () => {
    const increasedValue = isInteger(inputValue) ? inputValue + 1 : 1;

    setInputValue(increasedValue);
    setCurrentInputMethod(AddToBasketMethod.BUTTON);
  };

  const handleOnBlur = () => {
    setIsInputActive(false);
  };

  const handleOnFocus = () => {
    setIsInputActive(true);
  };

  return (
    <CounterInput
      value={inputValue}
      onDecrease={handleDecrease}
      onIncrease={handleIncrease}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      maxValue={maxValue}
      disableIncreaseReason={disableIncreasePickerReason}
      showErrorAbsolute={showErrorAbsolute}
      invalidFieldMessage={invalidFieldMessage}
      disabled={disabled}
      textFieldAriaLabel={textFieldAriaLabel}
    />
  );
};

export default CounterPicker;
