/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'The selected range contains unavailable terms',
  'PLN': 'zł',
  'EUR': '€',
  'validPostalCodeLength': 'Enter a valid postal code (e.g. 00-000)',
  'minLength': 'Minimum number of characters: %{length}',
  'maxLength': 'Maximum  number of characters: %{length}',
  'parcelLockerSelected': 'Selected parcel locker:',
  'selectParcelLocker': 'Select parcel locker',
  'selectAnotherParcelLocker': 'Select another parcel locker',
  'parcelLockerPointError': 'Failed to obtain correct parcel locker address',
  'enterDeliveryPhoneNumber': 'Enter phone number for delivery',
  'onlineMethodsDescription': 'Other activities are delivered digitally.',
  'shippingMethodRequired': 'Please select a shipping method before proceeding to payment.',
  'chooseShippingMethod': 'Choose shipping method',
  'generateWithAmount': 'Generate (%{amountToPay})',
  'additionsHeader': 'Additions: %{activityName}',
  'additionsLimitReached': 'You\'ve reached the limit of additions',
  'perPersonNumber': '%{number} pers. x',
  'selectedDayIsNotAvailable': 'Date %{date} is not available',
  'selectedDateIsNotAvailable': 'Date %{date} is not available',
  'errorOccurredWhileFetchingOrder': 'We couldn\'t load your order. If you\'ve completed the payment, please check your inbox for confirmation.',
  'priceTooHigh': 'The value of the selected services is too high. It is not possible to proceed to payment.',
  'invalidNip': 'VAT number is incorrect',
  'orderCreationError': 'Order creation error - try again',
  'activitiesFetchError': 'Cannot load facility activities',
  'chooseLimitReached': 'Limit of selected items reached',
  'localTours': 'Local tours',
  'clauseAcceptanceRequired': 'Clause acceptance required',
  'invalidPostalCode': 'Invalid postal code',
  'invalidNIPLength': 'Company NIP has invalid length',
  'invalidPhone': 'Invalid phone number',
  'invalidEmail': 'Invalid e-mail address',
  'invalidFormatError': 'Invalid format',
  'toLongError': 'Max number of characters is 30',
  'toShortError': 'Min number of characters is 3',
  'invalidTypeError': 'Invalid value',
  'missingFieldError': 'Cannot extract value from this field',
  'unknownError': 'Unknown error',
  'errorScriptNotFound': 'Error - your browser is not supported or invalid shop configuration',
  'addressRequired': 'Address required',
  'regulationAcceptRequired': 'Terms and Privacy Policy acceptance required',
  'regulationsAcceptRequired': 'Terms and Privacy Policy acceptance required',
  'phoneRequired': 'Phone number required',
  'lastNameRequired': 'Last name required',
  'firstNameRequired': 'First name required',
  'emailRequired': 'E-mail address required',
  'answerRequired': 'Answer required',
  'skippTheLine': 'Skip the ticket line',
  'downloadInPreviewIsDisabled': 'Orders cannot be downloaded in preview mode. The sample order was sent to the e-mail address provided.',
  'noValidDatesRange': 'No valid available dates range',
  'chooseNumberOfDays': 'Choose number of days',
  'day': 'day',
  'perPersonAbbr': 'pp',
  'maxNumberOfParticipants': 'Max number of participants is %{number}',
  'minNumberOfParticipants': 'Min number of participants is %{number}',
  'enterNumberOfParticipants': 'Enter the number of participants',
  'numberOfParticipants': 'Number of participants',
  'numberOfParticipantsBetween': 'Number of participants: from %{min} to %{max}',
  'generate': 'Generate',
  'seats': 'pcs.',
  'attention': 'Attention!',
  'forSinglePerson': 'for 1 person',
  'chooseAdditions': 'Choose additions',
  'pleaseChooseOneOption': 'Please select an option',
  'or': 'or',
  'addToAppleWallet': 'Add to Apple Wallet',
  'realiseOrder': 'Realise order',
  'generatingPdf': 'Generating PDF...',
  'transactionCompletedSuccessfully': 'Transaction completed successfully!',
  'immediatelyProceedThroughPayment': 'Once payment is confirmed, tickets will load below and be sent to %p',
  'optionally': 'optional',
  'shopOnline': 'Online shop',
  'orderOnline': 'Order online',
  'discountsAreDisabledInPreview': 'Discounts are disabled in preview mode',
  'unsuccessfullyOrderedSending': 'Sending order confirmation was unsuccessful to the %{email}. Try again later or send message to the pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Successfully sent confirmation to the %{email}',
  'requiredField': 'Field required',
  'forSendingAcceptTos': 'For sending, client accepts',
  'agreementFormWithClause': 'Agreement form with Information clause',
  'send': 'Send',
  'downloadToPrint': 'Download to print',
  'clientEmail': 'Client e-mail address',
  'chooseSendingConfirmationMethod': 'Ready! Choose how to provide confirmation to the customer',
  'orderPreviewInfo': 'Sample order was sent to the email: %p. If you don\'t see the message within a few minutes of making the payment, check your spam folder. If that doesn\'t work, enter "Droplabs" in your email program\'s search engine.',
  'exceededTimeout': 'Exceeded payment waiting time',
  'buyerWillSettleWithYou': 'The person who will use the services of the %p will settle with you',
  'youCanPrintTickets': 'After purchase, you can print your ready-to-use tickets. There will be no price at the tickets',
  'onlinePaymentForOrder': 'You pay for the order online. An invoice will be issued by %p for your company for a set period, usually collectively for the entire month.',
  'youWillPayForOrderBasedOnInvoice': 'You\'ll pay for the order on the basis of an invoice, which will be issued by the %p for your company for a set period, usually collectively for the entire month.',
  'wholeAmountWillBeChargedForUSed': 'The full amount will be charged by %p only for the tickets used. %p may impose an additional fee, also for unused tickets.',
  'youCanPrintReservationConfirmation': 'After booking, you can print the confirmation immediately.',
  'paymentWillBeChargedOnSite': 'The full amount of the order will have to be paid on the spot during the service.',
  'missingFacilityId': 'Invalid configuration.',
  'cannotLoadFacility': 'Cannot retrieve facility %p',
  'add': 'Add',
  'choose': 'Show',
  'choosing': 'Choose',
  'hide': 'Hide',
  'expand': 'Expand',
  'menuDetails': 'Details',
  'menuSummary': 'Payment',
  'menuSummaryThanks': 'Thank you for your order!',
  'noActivitiesInSales': 'Nothing to choose from. Check us out soon!',
  'hours': 'hrs',
  'minutes': 'min',
  'goToTheNextStep': 'Continue',
  'sellerOfActivities': '%{name} is the seller of the services.',
  'ifYouNeedHelpAssociatedWithOffer': 'If you have any questions related to this offer, contact the Seller at %p.',
  'saleSupportedByDroplabs': 'Droplabs - e-commerce, sales and distribution system for tickets, vouchers, passes and services.',
  'ifYouNeedTechnicalSupport': 'Need technical support? Contact us at %p',
  'activityAdmissionDateLabel': 'Admission date: %{name}',
  'chooseDayOfUseActivities': 'Select admission date',
  'chooseTimeOfUseActivities': 'Select admission datetime',
  'chooseNarrowedTimeOfUseActivities': 'Select narrowing of admission date',
  'chooseTime': 'Choose an hour',
  'chooseEmailForSendingTickets': 'What is your email address?',
  'email': 'Email address',
  'name': 'Name',
  'buyersName': 'Buyer\'s name',
  'surname': 'Surname',
  'buyersSurname': 'Buyer\'s surname',
  'goToThePayment': 'Make payment',
  'orderFreeTickets': 'Order',
  'buyWithoutPayment': 'Buy and pay later',
  'businessTicketsHaveBeenSentIntoEmail':
    'Ticket has been sent to the address: %p |||| Tickets have been sent to the address: %p',
  'checkSpamInCaseOfNotReceivingEmail':
    `If you don't see any email from us for a few minutes,
    check your spam folder or type Droplabs in the search bar in your mailbox.`,
  'buyAnotherActivities': 'Order more activities',
  'noFixedDatesForChosenActivities': 'Unfortunately, there is no available dates available for chosen services.',
  'forChosen': 'Applies to:',
  'chooseFixedDateBeforePayment': 'Please select admission date before proceeding.',
  'noFixedDatesForChosenPeriod': 'There is no available admission dates for chosen period.',
  'noFixedDatesOnDay': '%{date} is not available.',
  'noFixedDatesBetweenDates': 'No dates available between %{since} and %{until}.',
  'noFixedDatesBeforeDate': 'No dates available before %{date}.',
  'noFixedDatesAfterDate': 'No dates available after %{date}.',
  'next': 'Next',
  'back': 'Back',
  'onWebsite': 'booking URL',
  'january': 'January',
  'february': 'February',
  'march': 'March',
  'april': 'April',
  'may': 'May',
  'june': 'June',
  'july': 'July',
  'august': 'August',
  'september': 'September',
  'october': 'October',
  'november': 'November',
  'december': 'December',
  'sundayShort': 'Su',
  'sunday': 'Sunday',
  'mondayShort': 'Mo',
  'monday': 'Monday',
  'tuesdayShort': 'Tu',
  'tuesday': 'Tuesday',
  'wednesdayShort': 'We',
  'wednesday': 'Wednesday',
  'thursdayShort': 'Th',
  'thursday': 'Thursday',
  'fridayShort': 'Fr',
  'friday': 'Friday',
  'saturdayShort': 'Sa',
  'saturday': 'Saturday',
  'canNotBuyMoreTickets': 'You cannot buy more of this activity. No seats',
  'willEndSoon': 'Expires soon!',
  'itemsLeft': '%{limit} left!',
  'willEndBefore': 'Offer until %{date}!',
  'soldOut': 'Sold out!',
  'bookingRequired': 'Booking required after payment',
  'bookingAfterBuy': 'After the purchase you will receive %p for booking',
  'phone': 'a phone number',
  'website': 'a web address',
  'bookingEmail': 'an e-mail address',
  'more': 'more',
  'less': 'less',
  'from': 'From',
  'durationUnlimitedSentence': 'Full day',
  'durationUnlimited': 'full day',
  'useNumber': 'Number of admissions',
  'useDatetime': 'Admission',
  'betweenDates': 'between %{since} and %{until}',
  'onDate': 'on %{date}',
  'validBeforeDate': 'before %{date}',
  'validInDays': 'in %{number}',
  'today': 'today',
  'numberOfDays': 'day |||| days',
  'activeInDays': 'Valid: %{number} days after activation',
  'activationInDays': 'Activation in facility %{days} after buy',
  'activationDates': 'Activation in facility %{dates}',
  'pickFixedDateInTheNextStep': 'Check all available dates in the next step',
  'dateOfUse': 'Admission date',
  'withDiscountLabel': 'Do you have a discount code?',
  'optional': 'optional',
  'discountCode': 'Discount code',
  'useDiscountCode': 'Use code',
  'discountUsed': 'Active discount code (%{code}):',
  'invalidDiscountCode': 'Discount code (%{code}) doesn\'t work for chosen services.',
  'discountCodeNotFound': 'Discount code (%{code}) is invalid or outdatedy.',
  'discountAdditionsNotIncluded': 'Additions are not eligible for discount.',
  'notEligibleDiscountTickets': 'Other tickets are not eligible for this code',
  'close': 'close',
  'tryAgain': 'try again',
  'phoneNumber': 'Phone number',
  'contactPhoneNumber': 'Contact phone number',
  'clientPhoneNumber': 'Buyer\'s phone number',
  'restCountries': 'Rest countries',
  'sellerIs': 'The Seller and',
  'administratorOfThePersonalData': 'the Administrator of your personal data is',
  'sellerCopy':
    `Your data will be processed in particular for the purpose of performing the contract, i.e. enabling you to purchase a ticket, on the basis of Article 6(1)(b) of the GDPR.
     You have the right to access the content of your data and the right to rectify, delete, limit processing, the right to data portability, the right to object and to lodge a complaint to the supervisory authority.
     Your data is not subject to automated decision-making, including profiling.`,
  'infoAboutPrivacyPolicy': 'The full content of the information clause is available here',
  'businessSellerCopy': 'The seller is',
  'privacyPolicy': 'Privacy policy',
  'privacyPolicyAccept': 'Privacy policy',
  'termsOfServiceAgreement': 'I agree to',
  'termsOfService': 'Terms of service',
  'inTermsOfService': 'Terms of service',
  'inPrivacyPolicy': 'Privacy policy',
  'and': 'and',
  'acceptedTermsOfServiceIn': 'Accepted conditions you can find in',
  'facilityTermsOfService': '%{facilityName}\'s Terms of service',
  'activitiesLimitExceeded':
    'Oh, we are sold out for chosen services. Please buy other services.',
  'invalidDataOrCharacters': 'Provided data provided is invalid or contains invalid characters. Change them and try again.',
  'invalidAdmissionDate': 'Date is invalid.',
  'internalErrorMessage': 'Error, please try again later',
  'widgetDisabled': 'Sale disabled. Try again later.',
  'bubbleDisabled': 'Sale disabled!',
  'buyOnline': 'Buy online!',
  'buyVoucher': 'Buy voucher',
  'buyTicket': 'Buy ticket!',
  'bookingOnline': 'Book online',
  'buySkipass': 'Buy skipass',
  'buyCourse': 'Buy course',
  'iWantInvoice': 'I want to receive invoice',
  'address': 'Street and house/apartment number',
  'city': 'City',
  'postalCode': 'Postal code',
  'companyName': 'Company name',
  'requiredOnlyForInvoices': 'Required only for company invoices',
  'companyNIP': 'Tax Id',
  'optionalSentence': 'Optional',
  'invalidPrice': 'Cannot proceed to the payment because visible price is different than real - please refresh the page to load new data',
  'paymentDelayedInfo':
    'Payment dues for order is required based on VAT invoice which will be provided by %{companyName}',
  'oncePerDay': 'Entry once per day',
  'withoutLimit': 'Entry without daily limit',
  'ticketsReady': 'Ticket is ready! |||| Tickets are ready!',
  'pleaseWait': 'Please wait...',
  'downloadTickets': 'Download ticket |||| Download tickets',
  'download': 'Download',
  'loadingTickets': 'We are preparing your tickets!',
  'doOrder': 'Order',
  'doReservation': 'Reserve',
  'generateExampleOrder': 'Generate example order',
  'hotelNight': 'hotel night',
  'chooseHotelNight': 'Choose period of accommodation',
  'insertHotelNight': 'Add period of accommodation',
  'chooseHotelNightDescription': 'Entries for guests are only during accommodation.',
  'setNarrowedDates': 'You have to choose start and end accommodation dates',
  'endDateCantBeTheSameAsStart': 'Accommodation end have to be at least one day later than accommodation start',
  'save': 'Save',
  'insertYear': 'Insert year',
  'hour': 'hour',
  'realiseInDay': 'Realisation on %{date}',
  'realiseInDateWithTime': 'Realisation on %{date}, you choose time in the next step',
  'missingPaymentProvider': 'Payment has not been activated yet by seller. Try again later.',
  'seeOtherActivities': 'See another activities',
  'hideOtherActivities': 'Hide another activities',
  'givenDateIsSoldOut': 'Places for %{date} have been sold out',
  'isOutOfLimit': 'No seats for %{time}',
  'setDifferentHour': 'The number of available seats for %{time} is: %{numberOfSeats}, please select another time or date',
  'givenTimeIsSoldOut': 'Places for %{time} have been sold out',
  'hasNoSeatsForGivenDate': 'No seats for %{time}',
  'setDifferentDate': 'The number of available seats for %{date} is: %{availableSeats}, select another date',
  'seatsSoldOut': 'sold out',
  'hasNoSeats': 'no seats',
  'chooseDifferentDate': 'Choose different date',
  'actuallySoldOut': 'Actually sold out!',
  'noDates': 'Currently no dates!',
  'customerData': 'Customer data',
  'optionalText': 'optional',
  'numberOfParticipantsLabel': 'Number of participants: %{name}',
  'avaliableNumber': 'Seats remaining: %{number}',
  'filter': 'Filter',
  'clear': 'Clear',
  'checkItOut': 'Check it out',
  'remove': 'Remove',
  'thisMayInterestYou': 'This may interest you',
  'requiredActivityVariantsNotPresentInOrder': 'A different variant is required for purchase. Return to the list of services and select the appropriate variant.',
  'requiredActivityVariants': 'You are missing something in your order.',
  'backTo': 'Return to tab',
  'addSmall': 'add',
  'requiredActivity': 'required activity',
  'oneOfRequiredActivity': 'at least one required activity',
  'oneOfRequiredActivityVariants': 'at least one of the required activity variants',
  'to': 'Until',
  'marketingConsentsAgreement': 'I want to subscribe to the newsletter (Optional)',
  'marketingConsentsPromo': 'Stay up to date with all promotions and information, we send only the most important things, we promise zero spam!',
  'setAllConsents': 'Mark all',
  'InvalidNipFormat': 'Invalid Tax Id',
  'numberOfPeopleInGroup': 'Number of people in the group',
  'paymentFormOutside': 'Form of payment outside of Droplabs',
  'other': 'Other',
  'paymentTerminal': 'Payment terminal',
  'cash': 'Cash',
  'bankTransfer': 'Transfer to account',
  'prefix': 'Prefix',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Payment term is too short',
  'paymentCompleteUntilDaysLimitExceeded': 'Payment term is too long',
  'setPaymentDate': 'Set a payment date',
  'setPaymentDateDesc': 'If the customer does not pay for the order by the indicated date, the seats will return to the pool of available seats.',
  'newOrder': 'Create a new order',
  'LastStep': 'The final step is before you...',
  'copyLinkAndSendToCustomer': 'Copy the link and send it to the customer to pay for the order.',
  'orClick': 'Or click',
  'CopyLink': 'Copy link',
  'paymentOutside': 'Payment outside Droplabs',
  'paymentOnline': 'Online payment',
  'cancel': 'Cancel',
  'setDateAndTime': 'Select date and time',
  'privatePerson': 'Private individual',
  'company': 'Company',
  'invoiceOptionalNote': 'Invoice note (optional)',
  'youWillReceiveInvoiceSimplified': 'You will receive a receipt with your tax identification number (NIP), which is a simplified invoice.',
  'nameOptional': 'Name (optional)',
  'surnameOptional': 'Surname (optional)',
  'emailOptional': 'Email address (optional)',
  'isExpired': 'has expired',
  'isAdmissionDateInvalid': 'concerns a different realisation date',
  'isInvalid': 'is invalid',
  'isUsed': 'has been used',
  'buyerData': 'Buyer data',
  'requiredEmailAlert': 'Remember to provide the customer\'s email address, otherwise they will not receive the invoice.',
  'issueAnInvoice': 'Issue an invoice',
  'areYouLeaving': 'Are you going out already?',
  'exitConfirmationComment': 'By purchasing now, you are guaranteed an unchanged price. If you leave, you will lose the steps you have completed so far.',
  'abandonPurchase': 'Abandon your purchase',
  'continueShopping': 'Continue shopping',
  'thisFeatureIsNotEnabled': 'This feature is not available',
  'thisFeatureIsNotEnabledSolution': 'Nothing to lose! Write to us at %p, we will help you choose the right plan.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Please wait',
  'waitingForPaymentConfirmation': 'We are awaiting payment confirmation.',
  'paymentProcessInterrupted': 'If the payment process was interrupted, you can pay for the order',
  'clickHere': 'by clicking here',
  'ticketsLoadedAndSentToEmail': 'Tickets will be loaded below and sent to your email:',
  'paymentAccepted': 'Payment Accepted',
  'orderNumber': 'Order Number:',
  'orderSendOnEmail': 'Order confirmation was sent to email:',
  'emailNotGetThrough': 'Email not received?',
  'emailNotGetThroughTooltip': 'If you don\'t see the email within a few minutes after making the payment, check your spam folder. If that doesn\'t help, type \'Droplabs\' in the search of your email program.',
  'orderCreated': 'Order created',
  'generatingTickets': 'Generating Tickets',
  'generatingTicketsDescription': 'Soon you\'ll be able to download your ticket and add it to your virtual wallet. We\'ve also sent a copy of the same ticket to the provided email address.',
  'everythingDone': 'Everything\'s Ready!',
  'ticketsReadyToDownload': 'Tickets are ready for download. Click the button to download.',
  'pdfDocumentIsReady': 'Success, PDF Ready!',
  'clickForPdfDocument': 'PDF ready for download. Click the button to download.',
  'pdfDocumentIsGenerating': 'Generating PDF',
  'pdfDocumentIsGeneratingDescription': 'PDF generation may take a moment, you don\'t have to wait.',
  'generating': 'Generating...',
  'sendOrder': 'Send Order',
  'sendOrderDescription': 'Provide the email address to which the order, along with the ticket, should be sent.',
  'orderSendedOnEmail': 'The order has been sent to the provided email address',
  'orderSomethingElse': 'Would you like to order something else?',
  'actionDisabledInPreviewMode': 'This action cannot be performed, this view is for preview only.',
  'anotherRecipient': 'I want to specify another recipient',
  'recipientName': 'Name of recipient',
  'digitalShippingMethod': 'Digital delivery to a specified email address',
  'somethingIsWrong': 'Something went wrong...',
  'waitingForPaymentError': 'The order was canceled or the time to pay for the order has expired. Generate a new order or renew payment.',
  'waitingForPayment': 'Waiting for payment',
  'waitingForPaymentDesc': 'The order amount has been sent to the Viva Wallet terminal. Accept the payment as soon as the amount appears on the terminal screen. If you do not want to accept the payment, cancel the transaction.',
  'sendAgain': 'Send again',
  'paymentRepeatError': 'Failed to retry payment',
  'paymentRepeatSuccess': 'The payment has been resubmitted',
  'rePayment': 'Retry Payment',
  'setTicketAsUsed': 'Mark as Used',
  'setTicketAsUsedDescription': 'Log the usage of all newly generated tickets, valid for today.',
  'useTickets': 'Use Tickets',
  'useTicketsSuccess': 'Tickets have been successfully marked as used.',
  'useTicketsError': 'Unable to mark tickets as used.',
  'mismatchedTicketDate': 'Order relates to a different fulfillment date.',
  'ticketInactive': 'Order is not active.',
  'useTicketsErrorMoreDetails': 'You can find order details in the \'Customer Support\' or \'Access Control\' section.',
  'country': 'Country',
  'notSavedData': 'Data will not be saved',
  'notSavedDataDesc': 'If you have entered additional data, remember that when you go to select items, you will lose this information.',
  'changeActivities': 'Change items',
  'AD': 'Andorra',
  'AE': 'United Arab Emirates',
  'AF': 'Afghanistan',
  'AG': 'Antigua and Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antarctica',
  'AR': 'Argentina',
  'AS': 'American Samoa',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Åland Islands',
  'AZ': 'Azerbaijan',
  'BA': 'Bosnia and Herzegovina',
  'BB': 'Barbados',
  'BD': 'Bangladesh',
  'BE': 'Belgium',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Bahrain',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint Barthélemy',
  'BM': 'Bermuda',
  'BN': 'Brunei Darussalam',
  'BO': 'Bolivia (Plurinational State of)',
  'BQ': 'Bonaire, Sint Eustatius and Saba',
  'BR': 'Brazil',
  'BS': 'Bahamas',
  'BT': 'Bhutan',
  'BV': 'Bouvet Island',
  'BW': 'Botswana',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'CA': 'Canada',
  'CC': 'Cocos (Keeling) Islands',
  'CD': 'Congo, Democratic Republic of the',
  'CF': 'Central African Republic',
  'CG': 'Congo',
  'CH': 'Switzerland',
  'CI': 'Côte d\'Ivoire',
  'CK': 'Cook Islands',
  'CL': 'Chile',
  'CM': 'Cameroon',
  'CN': 'China',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Cabo Verde',
  'CW': 'Curaçao',
  'CX': 'Christmas Island',
  'CY': 'Cyprus',
  'CZ': 'Czechia',
  'DE': 'Germany',
  'DJ': 'Djibouti',
  'DK': 'Denmark',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'DZ': 'Algeria',
  'EC': 'Ecuador',
  'EE': 'Estonia',
  'EG': 'Egypt',
  'EH': 'Western Sahara',
  'ER': 'Eritrea',
  'ES': 'Spain',
  'ET': 'Ethiopia',
  'FI': 'Finland',
  'FJ': 'Fiji',
  'FK': 'Falkland Islands (Malvinas)',
  'FM': 'Micronesia (Federated States of)',
  'FO': 'Faroe Islands',
  'FR': 'France',
  'GA': 'Gabon',
  'GB': 'United Kingdom of Great Britain and Northern Ireland',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'French Guiana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Greenland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Equatorial Guinea',
  'GR': 'Greece',
  'GS': 'South Georgia and the South Sandwich Islands',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Heard Island and McDonald Islands',
  'HN': 'Honduras',
  'HR': 'Croatia',
  'HT': 'Haiti',
  'HU': 'Hungary',
  'ID': 'Indonesia',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IM': 'Isle of Man',
  'IN': 'India',
  'IO': 'British Indian Ocean Territory',
  'IQ': 'Iraq',
  'IR': 'Iran (Islamic Republic of)',
  'IS': 'Iceland',
  'IT': 'Italy',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordan',
  'JP': 'Japan',
  'KE': 'Kenya',
  'KG': 'Kyrgyzstan',
  'KH': 'Cambodia',
  'KI': 'Kiribati',
  'KM': 'Comoros',
  'KN': 'Saint Kitts and Nevis',
  'KP': 'Korea (Democratic People\'s Republic of)',
  'KR': 'Korea, Republic of',
  'KW': 'Kuwait',
  'KY': 'Cayman Islands',
  'KZ': 'Kazakhstan',
  'LA': 'Lao People\'s Democratic Republic',
  'LB': 'Lebanon',
  'LC': 'Saint Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'LV': 'Latvia',
  'LY': 'Libya',
  'MA': 'Morocco',
  'MC': 'Monaco',
  'MD': 'Moldova, Republic of',
  'ME': 'Montenegro',
  'MF': 'Saint Martin (French part)',
  'MG': 'Madagascar',
  'MH': 'Marshall Islands',
  'MK': 'North Macedonia',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolia',
  'MO': 'Macao',
  'MP': 'Northern Mariana Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Maldives',
  'MW': 'Malawi',
  'MX': 'Mexico',
  'MY': 'Malaysia',
  'MZ': 'Mozambique',
  'NA': 'Namibia',
  'NC': 'New Caledonia',
  'NE': 'Niger',
  'NF': 'Norfolk Island',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Netherlands, Kingdom of the',
  'NO': 'Norway',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'New Zealand',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'French Polynesia',
  'PG': 'Papua New Guinea',
  'PH': 'Philippines',
  'PK': 'Pakistan',
  'PL': 'Poland',
  'PM': 'Saint Pierre and Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palestine, State of',
  'PT': 'Portugal',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Réunion',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'SA': 'Saudi Arabia',
  'SB': 'Solomon Islands',
  'SC': 'Seychelles',
  'SD': 'Sudan',
  'SE': 'Sweden',
  'SG': 'Singapore',
  'SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'SI': 'Slovenia',
  'SJ': 'Svalbard and Jan Mayen',
  'SK': 'Slovakia',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Suriname',
  'SS': 'South Sudan',
  'ST': 'Sao Tome and Principe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten (Dutch part)',
  'SY': 'Syrian Arab Republic',
  'SZ': 'Eswatini',
  'TC': 'Turks and Caicos Islands',
  'TD': 'Chad',
  'TF': 'French Southern Territories',
  'TG': 'Togo',
  'TH': 'Thailand',
  'TJ': 'Tajikistan',
  'TK': 'Tokelau',
  'TL': 'Timor-Leste',
  'TM': 'Turkmenistan',
  'TN': 'Tunisia',
  'TO': 'Tonga',
  'TR': 'Türkiye',
  'TT': 'Trinidad and Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan, Province of China',
  'TZ': 'Tanzania, United Republic of',
  'UA': 'Ukraine',
  'UG': 'Uganda',
  'UM': 'United States Minor Outlying Islands',
  'US': 'United States of America',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VA': 'Holy See',
  'VC': 'Saint Vincent and the Grenadines',
  'VE': 'Venezuela (Bolivarian Republic of)',
  'VG': 'Virgin Islands (British)',
  'VI': 'Virgin Islands (U.S.)',
  'VN': 'Viet Nam',
  'VU': 'Vanuatu',
  'WF': 'Wallis and Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'South Africa',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Nearest available date',
  'nearestDateWithHour': '%{day} at %{hour}',
  'tomorrow': 'tomorrow',
  'paymentMethod': 'Payment method',
  'secureFormDescription': 'Pay quickly with bank transfer / credit card',
  'typeGeneratedBlikCode': 'Enter the one-time code generated in your banking app',
  'blik': 'BLIK',
  'typeBlikCode': 'Enter the BLIK code',
  'blikCodePatternError': 'The code must contain 6 digits',
  'blikCode': 'BLIK Code',
  'simplifiedInvoice': 'Simplified',
  'fullInvoice': 'Full',
  'privateInvoice': 'Personal',
  'simplifiedInvoiceInformation': 'The customer will receive a receipt with NIP which is a simplified invoice. It can be issued only for companies registered in Poland. For foreign companies choose full invoice.',
  'isNotPolishNip': 'The NIP number provided is not Polish.',
  'polishNip': 'Polish NIP number',
  'checkTheBankingApp': 'Check the banking app',
  'confirmPaymentWithPin': 'Now confirm the payment with your PIN using the app on your phone.',
  'ticketsLoadedAfterConfirm': 'Tickets will load after payment confirmation and will be sent to email:',
  'paymentWithBlik': 'Pay with BLIK',
  'cannontLoadPage': 'Failed to load this page',
  'refreshPage': 'Refresh the page',
  'isSoldOut': 'Sold out',
  'removeSelectedActivities': 'Delete selected services',
  'removeSelectedServicesQuestion': 'Remove selected services?',
  'allServicesWillBeRemoved': 'All services will be removed and you will return to re-selection',
  'hourFull': 'Hour',
  'hasNoSeatsTest': 'No seats',
  'pleaseSelectDate': 'Please select a date',
  'pleaseSelectTime': 'Please select a time',
  'selected': 'Selected',
  'term': 'Date',
  'price': 'Price',
  'startDate': 'Start date',
  'endDate': 'End date',
  'daysQuanity': 'Number of days',
  'decrease': 'Decrease',
  'increase': 'Increase',
  'minValueError': 'The minimum value is %{value}',
  'maxValueError': 'The maximum value is %{value}',
  'numberOfActivities': 'Number of activities'
};

/* eslint-enable max-len */
