import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CalendarPanel from './CalendarPanel';
import {getMonthDates, LAST_MONTH_INDEX} from '@Utils/date';
import CalendarHeader from './CalendarHeader';
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import locale from '@Utils/locale';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getMonth,
  getYear,
  createDate,
  getFormattedDate,
  isSameDate,
  isDateInRange
} from '@Utils/dates/timezoneDates';
import Paper from '@mui/material/Paper';
import {Dayjs} from 'dayjs';
import Typography from '@mui/material/Typography';

type Props = {
  value: Dayjs | null,
  minDate: Dayjs,
  maxDate: Dayjs,
  onChange: (arg0: Dayjs) => void
}

type SelectedDateState = {
  date: string,
  selectionRangeStart: boolean,
  selectionRangeEnd: boolean,
}

const HotelNarrowedCalendar = ({value, minDate, maxDate, onChange}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const year = getYear(value ?? minDate);
  const month = getMonth(value ?? minDate);
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const datesGroupedByWeeks = getMonthDates(selectedYear, selectedMonth, timezone);
  const [selectedDate, setSelectedDate] = useState<SelectedDateState | null>(null);
  const [error, setError] = useState(null);

  function onChangeDate(date: string) {
    setError(null);
    const dateTz = createDate(date);
    const isAvaliable = isDateInRange(dateTz, minDate, maxDate);

    if (!isAvaliable) {
      return setError(locale.translate('selectedDayIsNotAvailable', {date}));
    }

    onChange(dateTz);

    setSelectedDate({
      date,
      selectionRangeStart: true,
      selectionRangeEnd: true
    });
  }

  function handleSelectPrevMonth() {
    if (selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
      return setSelectedMonth(LAST_MONTH_INDEX);
    }

    return setSelectedMonth(selectedMonth - 1);
  }

  function handleSelectNextMonth() {
    if (selectedMonth === LAST_MONTH_INDEX) {
      setSelectedYear(selectedYear + 1);
      return setSelectedMonth(0);
    }

    return setSelectedMonth(selectedMonth + 1);
  }

  return (
    <Paper
      elevation={8}
      sx={paperStyles}
    >
      <CalendarPanel
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        onPrevMonthClick={handleSelectPrevMonth}
        onNextMonthClick={handleSelectNextMonth}
      />
      <CalendarHeader />
      {
        datesGroupedByWeeks.map((week, weekIndex) => (
          <CalendarRow key={`week-${weekIndex}`} isHotelNarrowDate>
            {
              week.map((weekDate, dayIndex) => {
                const key = `day-${dayIndex}`;
                const isAvaliable = weekDate ? isDateInRange(weekDate, minDate, maxDate) : false;
                const isSelected = selectedDate?.date ? isSameDate(weekDate, selectedDate.date) : false;
                const dateData = {
                  ...selectedDate,
                  isSelected,
                  isAvaliable,
                  isHotelNarrowDate: true,
                  date: weekDate ? getFormattedDate(weekDate, DASH_DAY_FORMAT) : null
                };

                return (
                  <CalendarCell
                    calendarDay={weekDate ?? null}
                    cellData={dateData}
                    onClick={onChangeDate}
                    key={key}
                    isHotelNarrowDate
                  />
                );
              })
            }
          </CalendarRow>
        ))
      }
      {
        error &&
        <Typography color="error" sx={{mt: 2}}>
          {error}
        </Typography>
      }
    </Paper>
  );
};

export default HotelNarrowedCalendar;

const paperStyles = {
  position: 'absolute',
  top: '-16px',
  right: '50%',
  transform: 'translateX(50%)',
  zIndex: 999,
  width: '100vw',
  maxWidth: 400,
  p: 2,
  pt: 1
};
