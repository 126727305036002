import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Stack from '@mui/material/Stack';
import {Theme, useMediaQuery} from '@mui/material';

type Props = {
  children: React.ReactNode,
  isHeader?: boolean,
  showPrices?: boolean,
  showAvailableSeatsForDay?: boolean,
  isHotelNarrowDate?: boolean
}

const CalendarRow = ({
  children,
  isHeader,
  showPrices,
  showAvailableSeatsForDay,
  isHotelNarrowDate
}: Props) => {
  let rowHeight: string | number = 'auto';

  if (!isHeader) {
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm')
    );
    const isWithAllAdditionalInformation = showAvailableSeatsForDay && showPrices;

    if (isHotelNarrowDate) {
      rowHeight = isMobile ? 36 : 44;
    } else if (isWithAllAdditionalInformation) {
      rowHeight = isMobile ? 75 : 82;
    } else if (showPrices) {
      rowHeight = isMobile ? 64 : 70;
    } else {
      rowHeight = isMobile ? 59 : 64;
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        height: rowHeight,
        mb: isHeader ? 1 : 0
      }}
    >
      {children}
    </Stack>
  );

};

export default CalendarRow;
