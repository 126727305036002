import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import TimePickerOption from './TimePickerOption';
import './time-picker.scss';
import TimePickerCellsWrapper from './TimePickerCellsWrapper';
import TimePickerSelect from './TimePickerSelect';

const TimePicker = ({
  options, onChange, value, canShowItemsLeft, showPrices, isSelectErrorComponent, handleRemoveSelectError
}) => {
  let buttonHeight = 'auto';
  const isWithAllAdditionalInformation = canShowItemsLeft && showPrices;
  const isWithAdditionalInformation = canShowItemsLeft || showPrices;
  const showSelect = options.length > 8;

  if (isWithAllAdditionalInformation) {
    buttonHeight = 76;
  } else if (isWithAdditionalInformation) {
    buttonHeight = 62;
  } else {
    buttonHeight = 60;
  }

  return (
    <TimePickerCellsWrapper
      isSelect={showSelect}
    >
      {
        showSelect ? (
          <TimePickerSelect
            options={options}
            value={value}
            onChange={onChange}
            canShowItemsLeft={canShowItemsLeft}
            showPrices={showPrices}
            isWithAllAdditionalInformation={isWithAllAdditionalInformation}
            isSelectErrorComponent={isSelectErrorComponent}
            handleRemoveSelectError={handleRemoveSelectError}
          />
        ) :
          options.map(option => (
            <TimePickerOption
              key={option.originalDate}
              option={option}
              value={value}
              onChange={onChange}
              canShowItemsLeft={canShowItemsLeft}
              showPrices={showPrices}
              buttonHeight={buttonHeight}
              isWithAllAdditionalInformation={isWithAllAdditionalInformation}
              isWithAdditionalInformation={isWithAdditionalInformation}
            />
          ))
      }
    </TimePickerCellsWrapper>
  );
};

TimePicker.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  canShowItemsLeft: PropTypes.bool,
  showPrices: PropTypes.bool,
  isSelectErrorComponent: PropTypes.bool
};

export default TimePicker;
