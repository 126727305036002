import React, {useEffect, useState} from 'react';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {useDispatch, useSelector} from 'react-redux';
import locale from '@Utils/locale';
import dayjs from 'dayjs';
import {validateSelection} from './admissionRealizationUtils';
import {EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE} from '@Utils/events';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {getTimezoneDate} from '@Utils/dayjs/dayjsUtils';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {IDatesGroupedByDay} from './types';
import {IBasketItem} from '@Consts/types';
import {getShouldSelectDefaultDate} from './utils';
import {useDayOffer} from '@Utils/hooks/useDayOffer';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import Calendar from '@Components/calendar/Calendar';
import Box from '@mui/material/Box';
import {getBookingWindow} from '@Utils/bookingWindowUtils';
import MarkedHeader from '@Components/headers/MarkedHeader';

type Props = {
  datesGroupedByDay: IDatesGroupedByDay[] | null;
  activeViewBasketItems: IBasketItem[];
  dayOffers: DayOffersProcessed[];
  setError: (error: string | null) => void;
  onMonthChange: ({month, year}: {month: number, year: number}) => void;
  sendAmplitudeEvent: (key: string, props: object) => void;
  showPrices: boolean;
  showAvailableSeatsForDay: boolean;
}

const AdmissionCalendar = ({
  datesGroupedByDay,
  activeViewBasketItems,
  dayOffers,
  setError,
  onMonthChange,
  sendAmplitudeEvent,
  showPrices,
  showAvailableSeatsForDay
}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const [isDefaultDateSelected, setIsDefaultDateSelected] = useState(false);
  const basketItemSample = activeViewBasketItems[0];
  const {activity} = basketItemSample;
  const {admissionPolicy, passType} = activity;
  const {firstAdmissionDate, isOneAdmissionDateAvailable} = admissionPolicy;
  const {selections} = basketItemSample;
  const {numberOfDays} = selections;
  const dispatch = useDispatch();
  const selectedDate = selections?.dates?.[0]?.date || selections.dayOffer?.date;
  const initialSelectedDate = selectedDate ? getTimezoneDate(selectedDate, timezone) : null;
  const {handleChangeDayOffer} = useDayOffer(activeViewBasketItems, setError, sendAmplitudeEvent);

  function handleChange(selectedDate: string) {
    if (dayOffers) {
      return handleChangeDayOffer(selectedDate, dayOffers, timezone);
    }

    if (!datesGroupedByDay) {
      return null;
    }

    const selectedDateWithTimezone = getTimezoneDate(selectedDate, timezone, true);
    const selectedDatesGroupIndex = datesGroupedByDay.findIndex(({date}) => dayjs(date).isSame(dayjs(selectedDate)));
    const selectedDatesGroupedByDay = datesGroupedByDay[selectedDatesGroupIndex];
    const validationData = {
      datesGroupedByDay,
      selectedDatesGroupIndex,
      selectedDatesGroupedByDay,
      numberOfDays,
      selectedDate: selectedDateWithTimezone
    };
    const {validationError} = validateSelection(validationData);

    if (validationError) {
      dispatch(updateBasketItemsSelections(
        activeViewBasketItems, {dates: null, time: null}
      ));

      return setError(validationError);
    }

    const dates = datesGroupedByDay.slice(
      selectedDatesGroupIndex, selectedDatesGroupIndex + (numberOfDays ?? 1)
    );

    setError(validationError); // clear error

    if (dates?.[0]?.metaData?.isOutOfLimit === false) {
      // send event on select available date with available hours
      sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE, {
        admissionDateDay: selectedDateWithTimezone.format(DASH_DAY_FORMAT),
        numberOfSelectedDates: dates?.length,
        admissionDateDescription: dates?.[0]?.metaData?.description,
        bookingWindow: getBookingWindow(dates?.[0].date)
      });
    }

    dispatch(updateBasketItemsSelections(
      activeViewBasketItems, {dates, time: null}
    ));
  }

  useEffect(() => {
    if ((datesGroupedByDay || dayOffers?.length) && !isDefaultDateSelected) {
      setIsDefaultDateSelected(true);
      const shouldSelectDefaultDate = getShouldSelectDefaultDate(
        firstAdmissionDate,
        isOneAdmissionDateAvailable,
        passType,
        initialSelectedDate,
        datesGroupedByDay,
        dayOffers
      );

      if (shouldSelectDefaultDate) {
        handleChange(getTimezoneDate(firstAdmissionDate, timezone).format('YYYY-MM-DD'));
      }
    }
  }, [datesGroupedByDay, dayOffers]);

  return (
    <Box sx={{mb: 2}}>
      <MarkedHeader text={locale.translate('chooseDayOfUseActivities')} />
      <Calendar
        value={initialSelectedDate || getTimezoneDate(firstAdmissionDate, timezone)}
        datesGroupedByDay={datesGroupedByDay}
        dayOffers={dayOffers}
        onChange={handleChange}
        onMonthChange={onMonthChange}
        showPrices={showPrices}
        showAvailableSeatsForDay={showAvailableSeatsForDay}
      />
    </Box>
  );
};

export default AdmissionCalendar;
