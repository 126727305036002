import React from 'react';
import {Dayjs} from 'dayjs';
import {CalendarCellData} from '@Utils/hooks/useCalendarCell';
import {DefaultTextDay, TextSoldOutOrNoSeats} from './CalendarComponents';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';

type Props = {
    calendarDay: Dayjs,
    isDisabled: boolean,
    data: CalendarCellData,
    isHotelNarrowDate?: boolean
}

const DefaultCalendarCell = ({calendarDay, isDisabled, data, isHotelNarrowDate}: Props) => (
  <Box>
    <DefaultTextDay isDisabled={isDisabled} isToday={data?.isToday} isHotelNarrowDate={isHotelNarrowDate}>
      {calendarDay.date()}
    </DefaultTextDay>
    {
      data?.isSoldOut && (
        <TextSoldOutOrNoSeats>
          {locale.translate('isSoldOut')}
        </TextSoldOutOrNoSeats>
      )
    }
    {
      data?.hasNoSeats && (
        <TextSoldOutOrNoSeats>
          {locale.translate('hasNoSeats')}
        </TextSoldOutOrNoSeats>
      )
    }
  </Box>
);

export default DefaultCalendarCell;
