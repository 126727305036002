import {ELocale, EWidgetType} from '@Consts/globals';
import {isWidgetBusiness, isWidgetInternal} from './widgetType';
import {EVENT_PROPERTIES, USER_PROPERTIES} from './eventsConsts';
import {isString} from './validate';
import {EPaymentMethod} from '@Components/checkout/types';
import {EventProperties, GetUserIdProps, UserProperties} from './eventsUtilsTypes';

export const getUserType = (widgetType: EWidgetType) => {
  if (isWidgetBusiness(widgetType)) {
    return 'b2b_buyer';
  }

  if (isWidgetInternal(widgetType)) {
    return 'internal_buyer';
  }

  return 'individual_buyer';
};

export const parseLanguage = (locale: ELocale) => {
  if (!locale) {
    return null;
  }

  const LANGUAGES = {
    [ELocale.PL]: 'Polish',
    [ELocale.EN]: 'English',
    [ELocale.SK]: 'Slovak',
    [ELocale.CS]: 'Czech',
    [ELocale.DE]: 'German',
    [ELocale.IT]: 'Italian',
    [ELocale.UK]: 'Ukrainian',
    [ELocale.ES]: 'Spanish',
    [ELocale.HU]: 'Hungarian'
  };

  return LANGUAGES[locale] || null;
};

const PAYMENT_METHOD_NAME = {
  [EPaymentMethod.BLIK]: 'BLIK',
  [EPaymentMethod.SECURE_FORM]: 'secure form'
};

export const parsePaymentMethod = (paymentMethod: EPaymentMethod) => {
  if (paymentMethod === EPaymentMethod.BLIK || paymentMethod === EPaymentMethod.SECURE_FORM) {
    return {[EVENT_PROPERTIES.PAYMENT_METHOD]: PAYMENT_METHOD_NAME[paymentMethod]};
  }
};

export const getHash = (stringWithHash?: string | null) =>
  stringWithHash?.split(' ')[1]?.trim() || null;

export const isOnlineUserIdValid = (userId?: string | null) => {
  if (!userId) {
    return false;
  }

  if (!isString(userId)) {
    return false;
  }

  if (!userId.match('ONLINE: ')) {
    return false;
  }

  const hash = getHash(userId);

  if (hash && hash === 'undefined') {
    return false;
  }

  return true;
};

export const getUserId = ({widgetType, companyUserId, orderBuyerHash, currentUserId}: GetUserIdProps) => {
  if (isWidgetBusiness(widgetType) && companyUserId) {
    return `B2B: ${companyUserId}`;
  }

  if (isWidgetInternal(widgetType) && companyUserId) {
    return `INTERNAL: ${companyUserId}`;
  }

  if (orderBuyerHash === getHash(currentUserId)) {
    return currentUserId;
  }

  if (orderBuyerHash) {
    return `ONLINE: ${orderBuyerHash}`;
  }

  if (isOnlineUserIdValid(currentUserId)) {
    return currentUserId;
  }

  return null;
};

export const getEventProperties = ({
  userId,
  facilityId,
  facilityName,
  websiteUrl,
  results,
  language,
  widgetLanguage,
  industry,
  affiliationHash,
  onlineGroupId,
  invoiceDataSubmitted,
  numberOfTickets,
  formValues,
  requestBody,
  orderCode,
  orderId,
  orderState,
  orderPaymentUrl,
  error,
  formData,
  activityId,
  activityName,
  errorTitle,
  errorType,
  errorStatus,
  fetchingTime,
  datesSince,
  datesUtil,
  passType,
  priorityFromTopToBottom,
  originalLanguage,
  result,
  source,
  saleType,
  message,
  additions,
  questionText,
  questionType,
  required,
  items,
  shippingMethods,
  answerText,
  groupId,
  url,
  destinationUrl,
  description,
  validationError,
  responseData,
  basketItems,
  abTest,
  tag,
  revenueTotal,
  revenueFromUpsell,
  currency,
  chosenVariants,
  missingVariants,
  selectedShippingMethodId,
  isAgreeMarketingConsents,
  isFirstLoading,
  firstAdmissionDate,
  admissionDateDay,
  admissionDateDescription,
  admissionDateHour,
  userNeedToChooseHour,
  numberOfAvailableDays,
  numberOfAvailableHours,
  numberOfSelectedDates,
  month,
  widgetMode,
  paymentMethod,
  referrer,
  uuid,
  configuration,
  disabled,
  bookingWindow,
  country,
  nip,
  addToBasketMethod
}: EventProperties) => ({
  ...userId && {[EVENT_PROPERTIES.USER_ID]: userId},
  ...facilityId && {[EVENT_PROPERTIES.FACILITY]: facilityId},
  ...facilityName && {[EVENT_PROPERTIES.FACILITY_NAME]: facilityName},
  ...websiteUrl && {[EVENT_PROPERTIES.WEBSITE_URL]: websiteUrl},
  ...results && {[EVENT_PROPERTIES.RESULTS]: results},
  ...language && {[EVENT_PROPERTIES.LANGUAGE]: language},
  ...widgetLanguage && {[EVENT_PROPERTIES.WIDGET_LANGUAGE]: widgetLanguage},
  ...industry && {[EVENT_PROPERTIES.INDUSTRY]: industry},
  ...affiliationHash && {[EVENT_PROPERTIES.AFFILIATE_HASH]: affiliationHash},
  ...onlineGroupId && {[EVENT_PROPERTIES.ONLINE_GROUP]: onlineGroupId},
  ...invoiceDataSubmitted && {[EVENT_PROPERTIES.INVOICE_DATA_SUBMITTED]: invoiceDataSubmitted},
  ...numberOfTickets && {[EVENT_PROPERTIES.TICKETS_COUNT]: numberOfTickets},
  ...formValues && {[EVENT_PROPERTIES.FORM_VALUES]: formValues},
  ...requestBody && {[EVENT_PROPERTIES.REQUEST_BODY]: requestBody},
  ...orderCode && {[EVENT_PROPERTIES.ORDER_CODE]: orderCode},
  ...orderId && {[EVENT_PROPERTIES.ORDER]: orderId},
  ...orderState && {[EVENT_PROPERTIES.ORDER_STATE]: orderState},
  ...orderPaymentUrl && {[EVENT_PROPERTIES.ORDER_PAYMENT_URL]: orderPaymentUrl},
  ...error && {[EVENT_PROPERTIES.ERROR]: error},
  ...validationError && {[EVENT_PROPERTIES.VALIDATION_ERRORS]: validationError},
  ...formData && {[EVENT_PROPERTIES.FORM_DATA]: formData},
  ...responseData && {[EVENT_PROPERTIES.RESPONSE_DATA]: responseData},
  ...activityId && {[EVENT_PROPERTIES.ACTIVITY_ID]: activityId},
  ...activityName && {[EVENT_PROPERTIES.ACTIVITY_NAME]: activityName},
  ...errorTitle && {[EVENT_PROPERTIES.ERROR_TITLE]: errorTitle},
  ...errorType && {[EVENT_PROPERTIES.ERROR_TYPE]: errorType},
  ...errorStatus && {[EVENT_PROPERTIES.ERROR_STATUS]: errorStatus},
  ...fetchingTime && {[EVENT_PROPERTIES.FETCHING_TIME]: fetchingTime},
  ...datesSince && {[EVENT_PROPERTIES.DATES_SINCE]: datesSince},
  ...datesUtil && {[EVENT_PROPERTIES.DATES_UNTIL]: datesUtil},
  ...passType && {[EVENT_PROPERTIES.PASS_TYPE]: passType},
  ...priorityFromTopToBottom && {[EVENT_PROPERTIES.PRIORITY_FROM_TOP_TO_BOTTOM]: priorityFromTopToBottom},
  ...originalLanguage && {[EVENT_PROPERTIES.ORIGINAL_LANGUAGE]: originalLanguage},
  ...result && {[EVENT_PROPERTIES.RESULT]: result},
  ...source && {[EVENT_PROPERTIES.SOURCE]: source},
  ...saleType && {[EVENT_PROPERTIES.SALE_TYPE]: saleType},
  ...message && {[EVENT_PROPERTIES.MESSAGE]: message},
  ...additions && {[EVENT_PROPERTIES.ADDITIONS]: additions},
  ...questionText && {[EVENT_PROPERTIES.QUESTION_TEXT]: questionText},
  ...questionType && {[EVENT_PROPERTIES.QUESTION_TYPE]: questionType},
  ...required && {[EVENT_PROPERTIES.REQUIRED]: required},
  ...items && {[EVENT_PROPERTIES.ITEMS]: items},
  ...shippingMethods && {[EVENT_PROPERTIES.SHIPPING_METHODS]: shippingMethods},
  ...answerText && {[EVENT_PROPERTIES.ANSWER_TEXT]: answerText},
  ...groupId && {[EVENT_PROPERTIES.GROUP_ID]: groupId},
  ...url && {[EVENT_PROPERTIES.URL]: url},
  ...destinationUrl && {[EVENT_PROPERTIES.DESTINATION_URL]: destinationUrl},
  ...description && {[EVENT_PROPERTIES.DESCRIPTION]: description},
  ...basketItems && {[EVENT_PROPERTIES.BASKET_ITEMS]: basketItems},
  ...abTest && {[EVENT_PROPERTIES.AB_TEST]: abTest},
  ...tag && {[EVENT_PROPERTIES.TAG]: tag},
  ...(typeof revenueTotal === 'number') && {[EVENT_PROPERTIES.REVENUE_TOTAL]: revenueTotal},
  ...(typeof revenueFromUpsell === 'number') && {[EVENT_PROPERTIES.REVENUE_FROM_UPSELL]: revenueFromUpsell},
  ...currency && {[EVENT_PROPERTIES.CURRENCY]: currency},
  ...chosenVariants && {[EVENT_PROPERTIES.CHOSEN_VARIANTS]: chosenVariants},
  ...missingVariants && {[EVENT_PROPERTIES.MISSING_VARIANTS]: missingVariants},
  ...selectedShippingMethodId && {[EVENT_PROPERTIES.SELECTED_SHIPPING_METHOD]: selectedShippingMethodId},
  ...isAgreeMarketingConsents && {[EVENT_PROPERTIES.MARKETING_CONSENT]: isAgreeMarketingConsents},
  ...isFirstLoading && {[EVENT_PROPERTIES.IS_FIRST_LOADING]: isFirstLoading},
  ...firstAdmissionDate && {[EVENT_PROPERTIES.FIRST_ADMISSION_DATE]: firstAdmissionDate},
  ...admissionDateDay && {[EVENT_PROPERTIES.ADMISSION_DATE_DAY]: admissionDateDay},
  ...admissionDateDescription && {[EVENT_PROPERTIES.ADMISSION_DATE_DESCRIPTION]: admissionDateDescription},
  ...admissionDateHour && {[EVENT_PROPERTIES.ADMISSION_DATE_HOUR]: admissionDateHour},
  ...userNeedToChooseHour && {[EVENT_PROPERTIES.USER_NEED_TO_CHOOSE_HOUR]: userNeedToChooseHour},
  ...(typeof numberOfAvailableDays === 'number') &&
    {[EVENT_PROPERTIES.NUMBER_OF_AVAILABLE_DAYS]: numberOfAvailableDays},
  ...(typeof numberOfAvailableHours === 'number') &&
    {[EVENT_PROPERTIES.NUMBER_OF_AVAILABLE_HOURS]: numberOfAvailableHours},
  ...(typeof numberOfSelectedDates === 'number') &&
    {[EVENT_PROPERTIES.NUMBER_OF_SELECTED_DATES]: numberOfSelectedDates},
  ...month && {[EVENT_PROPERTIES.MONTH]: month},
  ...widgetMode && {[EVENT_PROPERTIES.WIDGET_MODE]: widgetMode},
  ...paymentMethod && parsePaymentMethod(paymentMethod),
  ...referrer && {[EVENT_PROPERTIES.REFERRER]: referrer},
  ...uuid && {[EVENT_PROPERTIES.UUID]: uuid},
  ...configuration && {[EVENT_PROPERTIES.CONFIGURATION]: configuration},
  ...(typeof disabled === 'boolean') && {[EVENT_PROPERTIES.DISABLED]: disabled},
  ...(typeof bookingWindow === 'number') && {[EVENT_PROPERTIES.BOOKING_WINDOW]: bookingWindow},
  ...country && {[EVENT_PROPERTIES.COUNTRY]: country},
  ...nip && {[EVENT_PROPERTIES.NIP]: nip},
  ...addToBasketMethod && {[EVENT_PROPERTIES.ADD_TO_BASKET_INPUT_METHOD]: addToBasketMethod}
});

export const getUserProperties = ({
  userId,
  companyUserId,
  companyID,
  userType,
  companyName,
  userName,
  language
}: UserProperties) => ({
  ...userType && {[USER_PROPERTIES.USER_TYPE]: userType},
  ...userId && {[USER_PROPERTIES.USER_ID]: userId},
  ...companyUserId && {[USER_PROPERTIES.DB_COMPANY_USER_ID]: companyUserId},
  ...companyID && {[USER_PROPERTIES.DB_COMPANY_ID]: companyID},
  ...companyName && {[USER_PROPERTIES.DB_COMPANY_NAME]: companyName},
  ...userName && {[USER_PROPERTIES.DB_USER_NAME]: userName},
  ...language && {[USER_PROPERTIES.LANGUAGE]: language}
});
