import {EPassType} from '@Consts/apiGlobals';
import {IBasketItemSelections} from '@Consts/types';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import React from 'react';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AdmissionSummaryDetail from './AdmissionSummaryDetail';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {getSummaryDetails} from './utils';
import {getFormattedPrice} from '@Utils/format';
import MarkedHeader from '@Components/headers/MarkedHeader';

type Props = {
    selections: IBasketItemSelections
    passType: EPassType
}

const AdmissionSummary = ({selections, passType}: Props) => {
  const {time: selectedTime, dates: selectedDates} = selections;
  const selectedTimeDescription = selectedTime?.description;
  const isSinglePickDate = isActivitySinglePickDate(passType);
  const isDateTimes = isActivityWithDateTimes(passType);
  const selectedDatesDescription = isSinglePickDate &&
  selectedDates?.[0]?.group[0]?.description;
  const isDatesRange = isSinglePickDate && !!selections?.dates?.length && selections?.dates.length > 1;
  const summaryDetails = getSummaryDetails(isDateTimes, isDatesRange, selections);

  if (!summaryDetails) {
    return null;
  }

  const {
    price: totalPrice,
    // catalogPrice,
    // catalogPriceDescription,
    currency,
    date,
    firstDate,
    lastDate
  } = summaryDetails;

  // const isDiscount = !!(catalogPrice && catalogPriceDescription && totalPrice < catalogPrice);
  const price = `${getFormattedPrice(totalPrice)} ${locale.translate(`${currency}`)}`;
  // const discountPrice = isDiscount ?
  //  `${getFormattedPrice(catalogPrice)} ${locale.translate(`${currency}`)}` : null;
  // const discountDescription = isDiscount ? catalogPriceDescription : null;

  return (
    <Box sx={{mb: 2}}>
      <Box sx={{mt: 3}}>
        <MarkedHeader text={locale.translate('selected')} />
      </Box>
      <Stack spacing={1}>
        <AdmissionSummaryDetail
          Icon={PaidOutlinedIcon}
          text={`${locale.translate('price')}: `}
          value={price}
          // discount={discountPrice}
          // description={discountDescription}
        />
        {isDatesRange ? (
          <AdmissionSummaryDetail
            Icon={EventAvailableOutlinedIcon}
            text={`${locale.translate('term')}: `}
          >
            <Stack
              direction="row"
              spacing={3}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box>
                <Typography variant="body2" sx={{mb: 0.5}}>
                  {locale.translate('startDate')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {firstDate}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{mb: 0.5}}>
                  {locale.translate('endDate')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {lastDate}
                </Typography>
              </Box>
            </Stack>
          </AdmissionSummaryDetail>
        ) : (
          <AdmissionSummaryDetail
            Icon={EventAvailableOutlinedIcon}
            text={`${locale.translate('term')}: `}
            value={date}
          />
        )}
        {
          (!!selectedTimeDescription || !!selectedDatesDescription) && (
            <Alert severity="info" sx={{mb: 1}}>
              {selectedTimeDescription || selectedDatesDescription}
            </Alert>
          )
        }
      </Stack>
    </Box>
  );
};

export default AdmissionSummary;
