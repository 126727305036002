import {IFacility} from '@Consts/apiGlobals';
import {getVariantDefaultCost} from '@Utils/activity/variant';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME, EGtmEvents} from './core';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import {clearEcommerce, getTotalPrice} from './utils';

enum EGtmEventKeys {
  GTM_ADD_KEY = 'add',
  GTM_REMOVE_KEY = 'remove'
}

type EventDataProps = {
  basketItemData: BasketItemData,
  event: EGtmEvents,
  onlineGroupId: number | null,
};

type GtmDataProps = {
  basketItemData: BasketItemData,
  event: EGtmEvents,
  eventKey: EGtmEventKeys,
};

export type BasketItemData = {
  activity: IActivityProcessed;
  variant: IActivityVariantProcessed;
  quantity?: number;
}

const getEcommerce = (
  basketItemData: BasketItemData,
  onlineGroupId: number | null
) => {
  const {activity, variant, quantity: itemQuantity} = basketItemData;
  const price = getVariantDefaultCost(variant)?.price;
  const quantity = itemQuantity ?? 1;

  return {
    currency: variant?.cost?.currency ?? variant.datesCostMin?.currency,
    value: getTotalPrice(variant, quantity),
    items: [{
      item_id: activity.id,
      item_name: activity?.name,
      item_list_id: onlineGroupId,
      item_variant: variant?.name,
      price,
      quantity
    }]
  };
};

const getEventData = ({
  basketItemData,
  event,
  onlineGroupId
}: EventDataProps) => ({
  event,
  ecommerce: getEcommerce(basketItemData, onlineGroupId)
});

const getEcommerceData = (
  basketItemData: BasketItemData,
  eventKey: EGtmEventKeys
) => {
  const {activity, variant, quantity: itemQuantity} = basketItemData;
  const price = getVariantDefaultCost(variant)?.price;
  const isAddToCart = eventKey === EGtmEventKeys.GTM_ADD_KEY;

  return {
    ...isAddToCart && {currencyCode: variant?.cost?.currency ?? variant.datesCostMin?.currency},
    [eventKey]: {
      products: [{
        id: activity.id,
        name: activity?.name,
        variant: variant?.name,
        price,
        quantity: itemQuantity ?? 1
      }]
    }
  };
};

const getGtmData = ({
  basketItemData,
  event,
  eventKey
}: GtmDataProps) => ({
  event,
  ecommerce: getEcommerceData(basketItemData, eventKey)
});

const addToCart = (facility: IFacility, basketItemData: BasketItemData, onlineGroupId: number | null) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  // Old Tag should still be sent
  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData({
      basketItemData,
      event: EGtmEvents.GTM_ADD_TO_CART,
      eventKey: EGtmEventKeys.GTM_ADD_KEY
    }
    )
  });

  // New Tag
  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getEventData({
      basketItemData,
      event: EGtmEvents.GTM_TAG_ADD_TO_CART,
      onlineGroupId
    })
  });
};

const removeFromCart = (facility: IFacility, basketItemData: BasketItemData, onlineGroupId: number | null) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  // Old Tag should still be sent
  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData({
      basketItemData,
      event: EGtmEvents.GTM_REMOVE_FROM_CART,
      eventKey: EGtmEventKeys.GTM_REMOVE_KEY
    })
  });

  // New Tag
  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getEventData({
      basketItemData,
      event: EGtmEvents.GTM_TAG_REMOVE_FROM_CART,
      onlineGroupId
    })
  });
};

export default {addToCart, removeFromCart};
