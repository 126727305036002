import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import {createRange} from '@Utils/utils';
import {getNumberOfDaysFormatted} from '@Utils/format';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MarkedHeader from '@Components/headers/MarkedHeader';

const NumberOfDaysSection = ({basketItems, variant}) => {
  const dispatch = useDispatch();
  const {numberOfDays} = basketItems[0].selections;
  const {
    numberOfAdmissionDatesMin: min,
    numberOfAdmissionDatesMax: max
  } = variant;

  const options = createRange(min, max)
    .map((number, index) => ({
      value: index + min,
      text: getNumberOfDaysFormatted(index + min)
    }));

  function handleChange({target}) {
    const numberOfDays = parseInt(target.value, 10);

    return dispatch(updateBasketItemsSelections(
      basketItems, {dates: null, numberOfDays}
    ));
  }

  if (options?.length === 1) {
    return null;
  }

  return (
    <Box sx={{mb: 3}}>
      <MarkedHeader text={locale.translate('chooseNumberOfDays')} />
      <FormControl
        sx={{
          width: 1,
          maxWidth: {md: 217}
        }}
      >
        <InputLabel id="number-of-days-label">{locale.translate('daysQuanity')}</InputLabel>
        <Select
          labelId="number-of-days-label"
          id="number-of-days-select"
          value={numberOfDays}
          label={locale.translate('daysQuanity')}
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {sx: {p: 0, maxHeight: 408}},
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            marginThreshold: 0,
            autoFocus: false,
            disableAutoFocus: true
          }}
        >
          {
            options.map(({text, value}) => (
              <MenuItem
                value={value}
                key={`${value}+${text}`}
                sx={{
                  py: 1.5,
                  px: 0,
                  pl: 2
                }}
              >{text}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
};

NumberOfDaysSection.propTypes = {
  basketItems: PropTypes.array.isRequired,
  variant: PropTypes.object.isRequired
};

export default NumberOfDaysSection;
