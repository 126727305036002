import React from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import locale from '../../utils/locale';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

type Props = {
  selectedYear: number,
  selectedMonth: number,
  onPrevMonthClick: () => void,
  onNextMonthClick: () => void
}

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

const CalendarPanel = ({selectedYear, selectedMonth, onPrevMonthClick, onNextMonthClick}: Props) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();

  // Do not disable when it is first date of month. We accept yesterday.
  const prevButtonDisabled = selectedYear <= currentYear && selectedMonth <= currentMonth && currentDate !== 1;
  const prevMonthClick = !prevButtonDisabled && {onClick: onPrevMonthClick};

  return (
    <Stack direction="row" sx={{justifyContent: 'space-between', alignItems: 'center', mb: 2, px: 1}}>
      <Typography sx={{fontSize: '18px', fontWeight: 500, color: 'text.primary'}}>
        {locale.translate(MONTHS[selectedMonth])} {selectedYear}
      </Typography>
      <Stack direction="row" spacing={2}>
        <IconButton {...prevMonthClick} disabled={prevButtonDisabled} sx={{p: 1.5}}>
          <ArrowBackIosNewOutlinedIcon sx={{fontSize: '20px'}} />
        </IconButton>
        <IconButton data-testid="next-month" onClick={onNextMonthClick} sx={{p: 1.5}}>
          <ArrowForwardIosOutlinedIcon sx={{fontSize: '20px'}} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CalendarPanel;
