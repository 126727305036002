import {IFacility} from '@Consts/apiGlobals';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME, EGtmEvents} from './core';
import {clearEcommerce} from './utils';

const viewItemList = (facility: IFacility, onlineGroupId: number) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {
      event: EGtmEvents.GTM_TAG_VIEW_ITEM_LIST,
      ecommerce: {
        item_list_id: onlineGroupId
      }
    }
  });
};

export default {
  viewItemList
};
