import React, {useMemo, useState} from 'react';
import CalendarPanel from './CalendarPanel';
import CalendarDays from './CalendarDays';
import {getMonthDates, LAST_MONTH_INDEX} from '@Utils/date';
import {Dayjs} from 'dayjs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';

type Props = {
  value: Dayjs,
  datesGroupedByDay: IDatesGroupedByDay[] | null,
  dayOffers: DayOffersProcessed[],
  onChange: (selectedDate: string) => void,
  onMonthChange: ({month, year}: {month: number, year: number}) => void,
  showPrices: boolean,
  showAvailableSeatsForDay: boolean
}

const Calendar = ({
  value,
  datesGroupedByDay,
  dayOffers,
  onChange,
  onMonthChange,
  showPrices,
  showAvailableSeatsForDay
}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const [selectedYear, setSelectedYear] = useState(value.get('year'));
  const [selectedMonth, setSelectedMonth] = useState(value.get('month'));
  const datesGroupedByWeeks = useMemo(() => getMonthDates(selectedYear, selectedMonth, timezone), [selectedMonth]);

  React.useEffect(() => {
    onMonthChange({month: selectedMonth, year: selectedYear});
  }, [selectedMonth]);

  function handleSelectPrevMonth() {
    if (selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
      return setSelectedMonth(LAST_MONTH_INDEX);
    }

    return setSelectedMonth(selectedMonth - 1);
  }

  function handleSelectNextMonth() {
    if (selectedMonth === LAST_MONTH_INDEX) {
      setSelectedYear(selectedYear + 1);
      return setSelectedMonth(0);
    }

    return setSelectedMonth(selectedMonth + 1);
  }

  return (
    <Box
      sx={{position: 'relative'}}
    >
      <CalendarPanel
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        onPrevMonthClick={handleSelectPrevMonth}
        onNextMonthClick={handleSelectNextMonth}
      />

      <CalendarDays
        weeks={datesGroupedByWeeks}
        datesGroupedByDay={datesGroupedByDay}
        dayOffers={dayOffers}
        onDayClick={onChange}
        showPrices={showPrices}
        showAvailableSeatsForDay={showAvailableSeatsForDay}
      />

      {!datesGroupedByDay && !dayOffers ? (
        <Box sx={loaderStyles}>
          <CircularProgress color="inherit" />
        </Box>
      ) : null}

    </Box>
  );
};

const loaderStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  backgroundColor: '#FFFFFFCC',
  color: 'grey.400'
};

export default Calendar;
