/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'A kiválasztott tartomány tartalmazza a nem elérhető kifejezéseket',
  'PLN': 'zł',
  'EUR': '€',
  'validPostalCodeLength': 'Adjon meg egy érvényes irányítószámot (pl. 00-000).',
  'minLength': 'A karakterek minimális száma: %{length}',
  'maxLength': 'A maximális karakterek száma: %{length}',
  'parcelLockerSelected': 'Kiválasztott csomagautomata:',
  'selectParcelLocker': 'Válasszon csomagautomatát',
  'selectAnotherParcelLocker': 'Válasszon másik csomagautomatát',
  'parcelLockerPointError': 'Nem sikerült helyes csomagcímhez jutni',
  'enterDeliveryPhoneNumber': 'Adja meg a telefonszámot a kézbesítéshez',
  'onlineMethodsDescription': 'Minden más szolgáltatás esetében elektronikus kézbesítés.',
  'shippingMethodRequired': 'A fizetés megkezdése előtt ki kell választania a szállítási módot.',
  'chooseShippingMethod': 'Válassza ki a szállítási módot',
  'generateWithAmount': 'Generálja (%{amountToPay})',
  'additionsHeader': 'Extrák: %{activityName}',
  'additionsLimitReached': 'Elérte a kibocsátási egységek határát',
  'perPersonNumber': '%{number} emberek x',
  'selectedDayIsNotAvailable': 'A %{date} nap nem elérhető.',
  'selectedDateIsNotAvailable': 'A %{date}-es dátum nem elérhető',
  'errorOccurredWhileFetchingOrder': 'A megrendelést nem lehetett betölteni. Ha már fizetett, kérjük, ellenőrizze az e-mailjét.',
  'priceTooHigh': 'A kiválasztott szolgáltatások értéke túl magas. Nem lehet folytatni a kifizetést.',
  'invalidNip': 'A TIN helytelen',
  'orderCreationError': 'Hiba történt a megrendelés létrehozása során, kérjük, próbálja meg újra.',
  'activitiesFetchError': 'Hiba történt a szolgáltatások betöltése közben',
  'chooseLimitReached': 'A kiválasztott darabok száma elérte a határértéket',
  'localTours': 'Helyi kirándulások',
  'clauseAcceptanceRequired': 'A záradék elfogadása szükséges',
  'invalidPostalCode': 'Helytelen irányítószám',
  'invalidNIPLength': 'A TIN-nek 10 számjegyűnek kell lennie',
  'invalidPhone': 'Helytelen telefonszám',
  'invalidEmail': 'Helytelen e-mail cím',
  'invalidFormatError': 'Helytelen formátum',
  'toLongError': 'Maximális hossza 30 karakter',
  'toShortError': 'A minimális hosszúság 3 karakter',
  'invalidTypeError': 'Helytelen érték',
  'missingFieldError': 'Ennek a mezőnek az értékét nem kaptuk meg',
  'unknownError': 'Ismeretlen hiba',
  'errorScriptNotFound': 'Hiba - Az Ön böngészője nem támogatott, vagy a boltot helytelenül ágyazták be.',
  'addressRequired': 'Szükséges címadatok',
  'regulationAcceptRequired': 'Az Általános Szerződési Feltételek és az Adatvédelmi Szabályzat elfogadása szükséges',
  'regulationsAcceptRequired': 'A felhasználási feltételek és az adatvédelmi irányelvek elfogadása szükséges',
  'phoneRequired': 'Szükséges telefonszám',
  'lastNameRequired': 'Szükséges név',
  'firstNameRequired': 'Szükséges név',
  'emailRequired': 'E-mail cím szükséges',
  'answerRequired': 'Válasz szükséges',
  'skippTheLine': 'Hagyja ki a sorban állást a jegypénztárnál',
  'downloadInPreviewIsDisabled': 'Előnézeti módban a megrendelés e-mailben kerül elküldésre, közvetlenül erről a képernyőről nem tölthető le.',
  'noValidDatesRange': 'Nincs elérhető dátumtartomány',
  'chooseNumberOfDays': 'Válassza ki a napok számát',
  'day': 'nap',
  'perPersonAbbr': 'emberek',
  'maxNumberOfParticipants': 'A résztvevők maximális száma %{number}',
  'minNumberOfParticipants': 'A résztvevők minimális száma %{number}',
  'enterNumberOfParticipants': 'Adja meg a résztvevők számát',
  'numberOfParticipants': 'A résztvevők száma',
  'numberOfParticipantsBetween': 'A résztvevők száma: %{min}-tól %{max}-ig',
  'generate': 'Generálja',
  'seats': 'db',
  'attention': 'Figyelem!',
  'forSinglePerson': '1 személyenként',
  'chooseAdditions': 'Válassza ki a tartozékokat',
  'pleaseChooseOneOption': 'A rendelkezésre álló lehetőségek közül egyet kell kiválasztani',
  'or': 'vagy',
  'addToAppleWallet': 'Hozzáadás az Apple Wallethez',
  'realiseOrder': 'Töltse ki a megrendelést',
  'generatingPdf': 'PDF generálás folyamatban...',
  'transactionCompletedSuccessfully': 'Sikeres tranzakció!',
  'immediatelyProceedThroughPayment': 'Amint megkaptuk a fizetési visszaigazolást, a jegyek betöltődnek és elküldésre kerülnek %p',
  'optionally': 'opcionális',
  'shopOnline': 'Online bolt',
  'orderOnline': 'Online rendelés',
  'discountsAreDisabledInPreview': 'A kedvezménykódok előnézeti módban le vannak tiltva',
  'unsuccessfullyOrderedSending': 'Nem sikerült elküldeni a megrendelés visszaigazolását %{email}. Kérjük, próbálja meg újra, vagy írjon a pomoc@droplabs.pl címre',
  'successfullyOrderedSending': 'Helyesen megrendelt küldött %{email}',
  'requiredField': 'Kötelező mező',
  'forSendingAcceptTos': 'Az ügyfél a szállítás céljából elfogadja a következőket',
  'agreementFormWithClause': 'Hozzájárulási nyilatkozat és tájékoztatási záradék',
  'send': 'Küldje el a',
  'downloadToPrint': 'Letöltés nyomtatáshoz',
  'clientEmail': 'Ügyfél e-mail címe',
  'chooseSendingConfirmationMethod': 'Kész! Válassza ki, hogyan szeretné elküldeni a visszaigazolást a vásárlónak.',
  'orderPreviewInfo': 'Az e-mail címre küldtünk egy rendelésmintát: %p. Ha néhány percen belül nem látja az üzenetet, ellenőrizze a spam mappát. Ha ez sem segít, írja be az e-mail program keresőjébe, hogy "Droplabs".',
  'exceededTimeout': 'Fizetési várakozási idő túllépése',
  'buyerWillSettleWithYou': 'Az a személy, aki igénybe veszi %p szolgáltatásait, kiszámlázza azokat Önnek.',
  'youCanPrintTickets': 'A vásárlás után azonnal kinyomtathatja a készen kapható belépőjegyeket. A belépőjegyeken nem lesz ár feltüntetve.',
  'onlinePaymentForOrder': 'A megrendelését online fizeti ki. Cégének %p számlát állítunk ki a megbeszélt időszakra, általában együttesen az egész hónapra.',
  'youWillPayForOrderBasedOnInvoice': 'Ön a megrendelését számlával fizeti ki, amelyet a %p egy meghatározott időszakra, általában együttesen az egész hónapra vonatkozóan állít ki az Ön vállalatának.',
  'wholeAmountWillBeChargedForUSed': 'A teljes összeget csak a használt jegyek esetében számoljuk fel %p-val. %p további díjat számíthat fel a fel nem használt belépőjegyek után is.',
  'youCanPrintReservationConfirmation': 'A foglalás után azonnal kinyomtathatja a visszaigazolást.',
  'paymentWillBeChargedOnSite': 'A megrendelés teljes összegét a helyszínen, a szolgáltatás során kell kifizetni.',
  'missingFacilityId': 'A boltot helytelenül ágyazták be',
  'cannotLoadFacility': 'A bolt betöltése sikertelen, kérjük, próbálja meg később újra.',
  'add': 'Add',
  'choose': 'Válassza ki',
  'choosing': 'Kiválasztás',
  'hide': 'Összeomlás',
  'expand': 'Bővíteni',
  'menuDetails': 'Részletek',
  'menuSummary': 'Fizetés',
  'menuSummaryThanks': 'Köszönjük, hogy leadta megrendelését!',
  'noActivitiesInSales': 'Nincsenek eladó szolgáltatások. Várjuk Önt hamarosan!',
  'hours': 'óra.',
  'minutes': 'min',
  'goToTheNextStep': 'Menj tovább',
  'sellerOfActivities': 'Az eladó %{name}.',
  'ifYouNeedHelpAssociatedWithOffer': 'Kérdése van az ajánlattal kapcsolatban? Írjon a %p.',
  'saleSupportedByDroplabs': 'Droplabs - Szolgáltatások, jegyek, utalványok és bérletek online boltja, értékesítési és forgalmazási rendszere.',
  'ifYouNeedTechnicalSupport': 'Műszaki támogatásra van szüksége? Írjon a %p címre',
  'activityAdmissionDateLabel': 'A végrehajtás időpontja: %{name}',
  'chooseDayOfUseActivities': 'Válassza ki a szolgáltatás napját',
  'chooseTimeOfUseActivities': 'Válassza ki a szolgáltatás szállítási idejét',
  'chooseNarrowedTimeOfUseActivities': 'Válassza ki a szolgáltatás szállítási idejének szűkítését',
  'chooseTime': 'Válasszon egy időpontot',
  'chooseEmailForSendingTickets': 'Melyik címre küldjem el a vásárlási visszaigazolást?',
  'email': 'E-mail cím',
  'name': 'Keresztnév',
  'buyersName': 'A vevő keresztnév',
  'surname': 'Vezetéknév',
  'buyersSurname': 'A vevő vezetéknév',
  'goToThePayment': 'Menjen a kifizetésekhez',
  'orderFreeTickets': 'Rendelés',
  'buyWithoutPayment': 'Fizetési kötelezettséggel vásárolok',
  'businessTicketsHaveBeenSentIntoEmail':
    'A bejegyzéseket az e-mail címre küldték: |||| A bejegyzéseket a következő e-mail címre küldték: %p',
  'checkSpamInCaseOfNotReceivingEmail':
    `Ha a fizetés után néhány perccel nem látja az üzenetet, ellenőrizze a spam mappáját.
    Ha ez sem segít, írja be az e-mail program keresőjébe, hogy "Droplabs".`,
  'buyAnotherActivities': 'További szolgáltatások megrendelése',
  'noFixedDatesForChosenActivities': 'Sajnos a kiválasztott jegyek számára nincsenek elérhető időpontok.',
  'forChosen': 'A következőkért:',
  'chooseFixedDateBeforePayment': 'Mielőtt a következő lépésre lépne, ki kell választania a kiválasztott jegyek dátumát.',
  'noFixedDatesForChosenPeriod': 'Ezen a napon nincsenek elérhető időpontok.',
  'noFixedDatesOnDay': 'Nincsenek elérhető időpontok %{date}.',
  'noFixedDatesBetweenDates': 'Nincs elérhető dátum %{since} - %{until} között.',
  'noFixedDatesBeforeDate': '%{date} előtt nincs elérhető időpont.',
  'noFixedDatesAfterDate': '%{date} után nincs szabad időpont.',
  'next': 'További',
  'back': 'Visszatérés',
  'onWebsite': 'a honlapon',
  'january': 'Január',
  'february': 'Február',
  'march': 'Március',
  'april': 'Április',
  'may': 'Május',
  'june': 'Június',
  'july': 'Július',
  'august': 'Augusztus',
  'september': 'Szeptember',
  'october': 'Október',
  'november': 'November',
  'december': 'December',
  'sundayShort': 'Va',
  'sunday': 'Vasárnap',
  'mondayShort': 'Hé',
  'monday': 'Hétfő',
  'tuesdayShort': 'Ke',
  'tuesday': 'Kedd',
  'wednesdayShort': 'Sz',
  'wednesday': 'Szerda',
  'thursdayShort': 'Cs',
  'thursday': 'Csütörtök',
  'fridayShort': 'Pé',
  'friday': 'Péntek',
  'saturdayShort': 'Sz',
  'saturday': 'Szombat',
  'canNotBuyMoreTickets': 'Több jegyet nem lehet vásárolni. Nincs szabad hely.',
  'willEndSoon': 'Hamarosan lejár!',
  'itemsLeft': 'Maradt %{limit}!',
  'willEndBefore': 'Ajánlat a %{date}!',
  'soldOut': 'Elfogyott!',
  'bookingRequired': 'Vásárlás után foglalás szükséges',
  'bookingAfterBuy': 'A vásárlás után kap egy %p-t a foglalásért.',
  'phone': 'tel.',
  'website': 'webcím',
  'bookingEmail': 'e-mail cím',
  'more': 'további',
  'less': 'kevesebb',
  'from': 'ár',
  'durationUnlimitedSentence': 'Nincs időkorlát',
  'durationUnlimited': 'nincs időkorlát',
  'useNumber': 'Bemenetek száma',
  'useDatetime': 'Végrehajtás',
  'betweenDates': '%{since}-től %{until}-ig',
  'onDate': 'a %{date}',
  'validBeforeDate': 'to %{date}',
  'validInDays': 'a %{number}',
  'today': 'ma',
  'numberOfDays': 'nap |||| nap',
  'activeInDays': 'Érvényesség: %{number} napok száma az aktiválástól számítva',
  'activationInDays': 'Aktiválás az objektumban %{days} vásárlás után',
  'activationDates': 'Aktiválás az objektumban %{dates}',
  'pickFixedDateInTheNextStep': 'Ellenőrizze az összes elérhető dátumot a következő lépésben',
  'dateOfUse': 'A végrehajtás időpontja',
  'withDiscountLabel': 'Van kedvezménykódja?',
  'optional': 'opcionálisan',
  'discountCode': 'Kedvezmény kód',
  'useDiscountCode': 'Használja a kódot',
  'discountUsed': 'Kedvezménykód aktív (%{code}):',
  'invalidDiscountCode': 'A megadott kód (%{code}) nem csökkenti a kiválasztott szolgáltatások árát.',
  'discountCodeNotFound': 'A megadott kód (%{code}) lejárt, érvénytelen vagy már felhasználták.',
  'discountAdditionsNotIncluded': 'Az extrákra nem vonatkozik a kedvezmény.',
  'notEligibleDiscountTickets': 'Egyéb jegyek nem tartoznak e kód alá.',
  'close': 'bezárás',
  'tryAgain': 'próbáld újra',
  'phoneNumber': 'Telefonszám',
  'contactPhoneNumber': 'Kapcsolattartó telefonszáma',
  'clientPhoneNumber': 'A vevő telefonszáma',
  'restCountries': 'Más országok',
  'sellerIs': 'Az eladó és',
  'administratorOfThePersonalData': 'A személyes adatok adatkezelője',
  'sellerCopy':
    `Az Ön adatait különösen a szerződés teljesítése érdekében dolgozzuk fel, azaz annak érdekében, hogy Ön jegyet vásárolhasson az adatvédelmi rendelet 6. cikke (1) bekezdésének b) pontja alapján.
    Ön jogosult az adatai tartalmához való hozzáférésre, valamint az adatok helyesbítéséhez, törléséhez, a feldolgozás korlátozásához, az adathordozhatósághoz, a tiltakozáshoz és a felügyeleti hatóságnál történő panasztételhez való joggal.
    Az Ön adatai nem képezik automatizált döntéshozatal tárgyát, ideértve a profilalkotást is.`,
  'infoAboutPrivacyPolicy': 'A tájékoztatási záradék teljes tartalma itt érhető el',
  'businessSellerCopy': 'Az eladó',
  'privacyPolicy': 'Adatvédelmi szabályzat',
  'privacyPolicyAccept': 'Adatvédelmi irányelvek',
  'termsOfServiceAgreement': 'Elolvastam és elfogadom',
  'termsOfService': 'Az értékesítés feltételei',
  'inTermsOfService': 'Szabályzat',
  'inPrivacyPolicy': 'Adatvédelmi irányelvek',
  'and': 'és',
  'acceptedTermsOfServiceIn': 'Az együttműködés elfogadott feltételei megtalálhatók a következő dokumentumban',
  'facilityTermsOfService': 'Szabályzat %{facilityName}',
  'activitiesLimitExceeded':
    'Valaki megelőzte Önt, időközben csökkent a rendelkezésre álló jegyek száma. Kérjük, válasszon másokat.',
  'invalidDataOrCharacters': 'Az adat hibás vagy érvénytelen karaktereket tartalmaz. Javítsa ki őket, és próbálja meg újra.',
  'invalidAdmissionDate': 'A dátum helytelen.',
  'internalErrorMessage': 'Hiba történt, kérjük, próbálja meg újra egy pillanat múlva.',
  'widgetDisabled': 'Az online értékesítés jelenleg kizárt.',
  'bubbleDisabled': 'Eladó kizárva!',
  'buyOnline': 'Vásároljon online!',
  'buyVoucher': 'Vásároljon utalványt',
  'buyTicket': 'Vegyen jegyet!',
  'bookingOnline': 'Foglaljon online',
  'buySkipass': 'Vásároljon síbérletet',
  'buyCourse': 'Vásároljon tanfolyamot',
  'iWantInvoice': 'Számlát szeretnék kapni',
  'address': 'Utca és házszám/helyiségek száma',
  'city': 'Város',
  'postalCode': 'Irányítószám',
  'companyName': 'Cégnév',
  'requiredOnlyForInvoices': 'Csak vállalati számlák esetében kötelező',
  'companyNIP': 'Vállalat TIN száma',
  'optionalSentence': 'Opcionális',
  'invalidPrice': 'A fizetés nem folytatható, mivel a feltüntetett ár eltér a tényleges ártól - kérjük, frissítse az oldalt a frissített adatok lekérdezéséhez.',
  'paymentDelayedInfo': 'A megrendelés kifizetése a %{companyName} által kiállítandó HÉA-számla alapján történik.',
  'oncePerDay': 'Naponta egy nevezés',
  'withoutLimit': 'Nincs napi belépési korlát',
  'ticketsReady': 'A belépődíj készen áll! |||| A nevezések készen állnak!',
  'pleaseWait': 'Kérjük várjon...',
  'downloadTickets': 'A jegy letöltése |||| A jegy letöltése',
  'download': 'Letöltés',
  'loadingTickets': 'A jegyek már készülnek!',
  'doOrder': 'Rendelés',
  'doReservation': 'Fenntartom',
  'generateExampleOrder': 'Minta rendelés generálása',
  'hotelNight': 'nap',
  'chooseHotelNight': 'Válassza ki az éjszakai tartózkodás időtartamát',
  'insertHotelNight': 'Adja meg az éjszakai tartózkodás időtartamát',
  'chooseHotelNightDescription': 'A belépőjegyek a vendégek számára csak az éjszakai tartózkodás idejére érvényesek.',
  'setNarrowedDates': 'Ki kell választania a tartózkodás kezdő és befejező dátumát.',
  'endDateCantBeTheSameAsStart': 'A tartózkodás végének legalább egy nappal későbbre kell esnie, mint a tartózkodás kezdete.',
  'save': 'Mentés',
  'insertYear': 'Adja meg az évet',
  'hour': 'óra.',
  'realiseInDay': 'A nap végrehajtása %{date}',
  'realiseInDateWithTime': 'A nap megvalósítása %{date}, a következő lépésben kiválasztott időpont.',
  'missingPaymentProvider': 'A kereskedő még nem aktiválta a fizetés elfogadását. Kérjük, próbálja meg hamarosan újra.',
  'seeOtherActivities': 'Lásd más javaslatok',
  'hideOtherActivities': 'Egyéb javaslatok elrejtése',
  'givenDateIsSoldOut': 'A %{date}-re már elfogytak a helyek',
  'isOutOfLimit': 'Nincs elég hely %{time}-ra.',
  'setDifferentHour': 'A %{time}-re rendelkezésre álló helyek száma: %{numberOfSeats}, kérjük, válasszon másik időpontot vagy dátumot',
  'givenTimeIsSoldOut': 'A %{time}-re szóló helyek elfogytak',
  'hasNoSeatsForGivenDate': 'Nincs elég hely %{date}.',
  'setDifferentDate': 'A rendelkezésre álló helyek száma %{date}-re a következő: %{availableSeats}, kérjük, válasszon másik időpontot vagy dátumot',
  'seatsSoldOut': 'Elfogyott',
  'hasNoSeats': 'nincs hely',
  'chooseDifferentDate': 'Válasszon másik időpontot',
  'actuallySoldOut': 'Elfogyott!',
  'noDates': 'Nincsenek határidők!',
  'customerData': 'Ügyféladatok',
  'optionalText': 'opcionálisan',
  'numberOfParticipantsLabel': 'A résztvevők száma: %{name}',
  'avaliableNumber': 'Fennmaradó helyek: %{number}',
  'filter': 'Szűrő',
  'clear': 'Tiszta',
  'checkItOut': 'Nézd meg',
  'remove': 'Törlés',
  'thisMayInterestYou': 'Ez érdekelheti Önt',
  'requiredActivityVariantsNotPresentInOrder': 'A vásárláshoz egy másik változat szükséges. Térjen vissza a szolgáltatások listájához, és válassza ki a megfelelő változatot.',
  'requiredActivityVariants': 'Valami hiányzik a rendeléséből.',
  'backTo': 'Vissza a lapra',
  'addSmall': 'add',
  'requiredActivity': 'az igényelt szolgáltatás',
  'oneOfRequiredActivity': 'az előírt szolgáltatások közül legalább egy',
  'oneOfRequiredActivityVariants': 'az előírt szolgáltatási lehetőségek közül legalább egy',
  'to': 'Amíg',
  'marketingConsentsAgreement': 'Szeretnék feliratkozni a hírlevélre (nem kötelező)',
  'marketingConsentsPromo': 'Maradjon naprakész az összes promócióval és információval, csak a legfontosabb információkat küldjük, ígérjük, hogy nulla spam!',
  'setAllConsents': 'Jelöljön meg mindent',
  'InvalidNipFormat': 'Helytelen TIN',
  'numberOfPeopleInGroup': 'A csoport létszáma',
  'paymentFormOutside': 'A fizetés formája a Droplabs-on kívül',
  'other': 'Egyéb',
  'paymentTerminal': 'Fizetési terminál',
  'cash': 'Készpénz',
  'bankTransfer': 'Átutalás a számlára',
  'prefix': 'Előtag',
  'paymentCompleteUntilBeforeMinimumDateTime': 'A fizetési időszak túl rövid',
  'paymentCompleteUntilDaysLimitExceeded': 'A fizetési időszak túl hosszú',
  'setPaymentDate': 'Fizetési határidő kitűzése',
  'setPaymentDateDesc': 'Ha az ügyfél nem fizeti ki a megrendelést a megadott időpontig, a helyek visszakerülnek a rendelkezésre álló helyek közé.',
  'newOrder': 'Új megrendelés létrehozása',
  'LastStep': 'Az utolsó lépés előtted...',
  'copyLinkAndSendToCustomer': 'Másolja ki a linket, és küldje el a vásárlónak, hogy ki tudja fizetni a rendelést.',
  'orClick': 'vagy kattintson',
  'CopyLink': 'Link másolása',
  'paymentOutside': 'Fizetés kívül Droplabs',
  'paymentOnline': 'Online fizetés',
  'cancel': 'Törölje',
  'setDateAndTime': 'Válassza ki a dátumot és az időt',
  'privatePerson': 'Magánszemély',
  'company': 'Cég',
  'invoiceOptionalNote': 'Számlajegyzet (nem kötelező)',
  'youWillReceiveInvoiceSimplified': 'Egyszerűsített számlaként egy TIN-t tartalmazó nyugtát fog kapni.',
  'nameOptional': 'Keresztnév (nem kötelező)',
  'surnameOptional': 'Vezetéknév (nem kötelező)',
  'emailOptional': 'E-mail cím (nem kötelező)',
  'isExpired': 'lejárt',
  'isAdmissionDateInvalid': 'más átfutási időre vonatkozik',
  'isInvalid': 'helytelen',
  'isUsed': 'használják',
  'buyerData': 'Vevői információk',
  'requiredEmailAlert': 'Ne felejtse el megadni az ügyfél e-mail címét, különben nem kap számlát.',
  'issueAnInvoice': 'Számla kiállítása',
  'areYouLeaving': 'Máris elmész?',
  'exitConfirmationComment': 'Ha most vásárol, garantáltan változatlan árat kap. Ha kilép, elveszíti az eddig elvégzett lépéseket.',
  'abandonPurchase': 'Hagyjon fel a vásárlással',
  'continueShopping': 'Folytassa a vásárlást',
  'thisFeatureIsNotEnabled': 'Ez a funkció nem elérhető',
  'thisFeatureIsNotEnabledSolution': 'Nincs vesztenivalód! Írjon nekünk a %p címre, segítünk kiválasztani a megfelelő csomagot.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Kérjük, várjon',
  'waitingForPaymentConfirmation': 'Várjuk a kifizetés visszaigazolását.',
  'paymentProcessInterrupted': 'Ha a fizetési folyamat megszakadt, fizethet a megrendeléséért.',
  'clickHere': 'ide kattintva',
  'ticketsLoadedAndSentToEmail': 'A bejegyzések az alábbiakban töltődnek be, és e-mailben elküldjük Önnek:',
  'paymentAccepted': 'Elfogadott fizetés',
  'orderNumber': 'Rendelési szám:',
  'orderSendOnEmail': 'A megrendelés visszaigazolása e-mailben érkezett:',
  'emailNotGetThrough': 'Üzenet nem érkezett meg?',
  'emailNotGetThroughTooltip': 'Ha a fizetés után néhány perccel nem látja az üzenetet, ellenőrizze a spam mappáját. Ha ez sem segít, írja be az e-mail program keresőjébe, hogy "Droplabs".',
  'orderCreated': 'Létrehozott sorrend',
  'generatingTickets': 'Jegyek generálása folyamatban',
  'generatingTicketsDescription': 'Hamarosan letöltheti a jegyét, és hozzáadhatja a virtuális pénztárcájához. Ugyanennek a jegynek egy másolatát is elküldjük a megadott e-mail címre.',
  'everythingDone': 'Minden kész!',
  'ticketsReadyToDownload': 'A bejegyzések letölthetők. Kattintson a gombra a letöltéshez.',
  'pdfDocumentIsReady': 'Siker, PDF kész!',
  'clickForPdfDocument': 'PDF letölthető. Kattintson a gombra a letöltéshez.',
  'pdfDocumentIsGenerating': 'PDF generálás folyamatban',
  'pdfDocumentIsGeneratingDescription': 'A PDF elkészítése eltarthat egy ideig, de nem kell várnia.',
  'generating': 'Generálása...',
  'sendOrder': 'Rendelés küldése',
  'sendOrderDescription': 'Adja meg azt az e-mail címet, amelyre a megrendelését a belépőjeggyel együtt elküldik.',
  'orderSendedOnEmail': 'A megrendelést a megadott e-mail címre küldték',
  'orderSomethingElse': 'Szeretne valami mást rendelni?',
  'actionDisabledInPreviewMode': 'Ezt nem lehet megtenni, ez a nézet csak illusztráció.',
  'anotherRecipient': 'Másik címzettet szeretnék megadni',
  'recipientName': 'A címzett neve',
  'digitalShippingMethod': 'Elektronikus küldés egy kijelölt e-mail címre',
  'somethingIsWrong': 'Valami elromlott...',
  'waitingForPaymentError': 'A megrendelését törölték, vagy a fizetési határidő lejárt. Hozzon létre új megrendelést vagy újítsa meg a fizetését.',
  'waitingForPayment': 'Várakozás a fizetésre',
  'waitingForPaymentDesc': 'A megrendelés összege elküldésre került a Viva Wallet terminálra. Fogadja el a fizetést, amint az összeg megjelenik a terminál képernyőjén. Ha nem kívánja elfogadni a fizetést, törölje a tranzakciót.',
  'sendAgain': 'Újraküldés',
  'paymentRepeatError': 'Nem fizetett vissza',
  'paymentRepeatSuccess': 'A kifizetést megújították',
  'rePayment': 'Visszafizetés',
  'setTicketAsUsed': 'Használati utasítás',
  'setTicketAsUsedDescription': 'Naplózza az összes most generált, ma érvényes bérlet használatát.',
  'useTickets': 'Naplóhasználat',
  'useTicketsSuccess': 'Helyesen naplózott bejegyzések.',
  'useTicketsError': 'Nem lehet naplózni a használatot.',
  'mismatchedTicketDate': 'A megrendelés más átfutási időre szól.',
  'ticketInactive': 'A megrendelés nem aktív.',
  'useTicketsErrorMoreDetails': 'Megrendelésének részleteit az "Ügyfélszolgálat" vagy a "Hozzáférés-ellenőrzés" menüpontban tekintheti meg.',
  'country': 'Ország',
  'notSavedData': 'Az adatok nem kerülnek mentésre',
  'notSavedDataDesc': 'Ha további adatokat adott meg, vegye figyelembe, hogy a szolgáltatások kiválasztásakor elveszíti ezeket az információkat.',
  'changeActivities': 'Szolgáltatások módosítása',
  'AD': 'Andorra',
  'AE': 'Egyesült Arab Emírségek',
  'AF': 'Afganisztán',
  'AG': 'Antigua és Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albánia',
  'AM': 'Örményország',
  'AO': 'Angola',
  'AQ': 'Antarktisz',
  'AR': 'Argentína',
  'AS': 'Amerikai Szamoa',
  'AT': 'Ausztria',
  'AU': 'Ausztrália',
  'AW': 'Aruba',
  'AX': 'Åland-szigetek',
  'AZ': 'Azerbajdzsán',
  'BA': 'Bosznia-Hercegovina',
  'BB': 'Barbados',
  'BD': 'Banglades',
  'BE': 'Belgium',
  'BF': 'Burkina Faso',
  'BG': 'Bulgária',
  'BH': 'Bahrein',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint-Barthélemy',
  'BM': 'Bermuda',
  'BN': 'Brunei',
  'BO': 'Bolívia',
  'BQ': 'Bonaire, Sint Eustatius és Saba',
  'BR': 'Brazília',
  'BS': 'Bahama-szigetek',
  'BT': 'Bhután',
  'BV': 'Bouvet-sziget',
  'BW': 'Botswana',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'CA': 'Kanada',
  'CC': 'Cocos-szigetek',
  'CD': 'Kongói Demokratikus Köztársaság',
  'CF': 'Közép-afrikai Köztársaság',
  'CG': 'Kongó',
  'CH': 'Svájc',
  'CI': 'Elefántcsontpart',
  'CK': 'Cook-szigetek',
  'CL': 'Chile',
  'CM': 'Kamerun',
  'CN': 'Kína',
  'CO': 'Kolumbia',
  'CR': 'Costa Rica',
  'CU': 'Kuba',
  'CV': 'Zöld-foki-szigetek',
  'CW': 'Curaçao',
  'CX': 'Karácsony-sziget',
  'CY': 'Ciprus',
  'CZ': 'Cseh Köztársaság',
  'DE': 'Németország',
  'DJ': 'Djibouti',
  'DK': 'Dánia',
  'DM': 'Dominika',
  'DO': 'Dominikai Köztársaság',
  'DZ': 'Algéria',
  'EC': 'Ecuador',
  'EE': 'Észtország',
  'EG': 'Egyiptom',
  'EH': 'Nyugat-Szahara',
  'ER': 'Eritrea',
  'ES': 'Spanyolország',
  'ET': 'Etiópia',
  'FI': 'Finnország',
  'FJ': 'Fidzsi',
  'FK': 'Falkland-szigetek',
  'FM': 'Mikronézia',
  'FO': 'Feröer-szigetek',
  'FR': 'Franciaország',
  'GA': 'Gabon',
  'GB': 'UK',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'Francia Guyana',
  'GG': 'Guernsey',
  'GH': 'Ghána',
  'GI': 'Gibraltár',
  'GL': 'Grönland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Egyenlítői-Guinea',
  'GR': 'Görögország',
  'GS': 'Dél-Georgia és Dél-Sandwich-szigetek',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Bissau-Guinea',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Heard és McDonald-szigetek',
  'HN': 'Honduras',
  'HR': 'Horvátország',
  'HT': 'Haiti',
  'HU': 'Magyarország',
  'ID': 'Indonézia',
  'IE': 'Írország',
  'IL': 'Izrael',
  'IM': 'Man',
  'IN': 'India',
  'IO': 'Brit Indiai-óceáni Terület',
  'IQ': 'Irak',
  'IR': 'Irán',
  'IS': 'Izland',
  'IT': 'Olaszország',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordánia',
  'JP': 'Japán',
  'KE': 'Kenya',
  'KG': 'Kirgizisztán',
  'KH': 'Kambodzsa',
  'KI': 'Kiribati',
  'KM': 'Comore-szigetek',
  'KN': 'Saint Kitts és Nevis',
  'KP': 'Észak-Korea',
  'KR': 'Dél-Korea',
  'KW': 'Kuvait',
  'KY': 'Kajmán-szigetek',
  'KZ': 'Kazahsztán',
  'LA': 'Laosz',
  'LB': 'Libanon',
  'LC': 'Saint Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Libéria',
  'LS': 'Lesotho',
  'LT': 'Litvánia',
  'LU': 'Luxemburg',
  'LV': 'Lettország',
  'LY': 'Líbia',
  'MA': 'Marokkó',
  'MC': 'Monaco',
  'MD': 'Moldova',
  'ME': 'Montenegro',
  'MF': 'Saint-Martin',
  'MG': 'Madagaszkár',
  'MH': 'Marshall-szigetek',
  'MK': 'Észak-Macedónia',
  'ML': 'Mali',
  'MM': 'Burma',
  'MN': 'Mongólia',
  'MO': 'Makaó',
  'MP': 'Észak-Mariana-szigetek',
  'MQ': 'Martinique',
  'MR': 'Mauritánia',
  'MS': 'Montserrat',
  'MT': 'Málta',
  'MU': 'Mauritius',
  'MV': 'Maldív-szigetek',
  'MW': 'Malawi',
  'MX': 'Mexikó',
  'MY': 'Malaysia',
  'MZ': 'Mozambik',
  'NA': 'Namíbia',
  'NC': 'Új-Kaledónia',
  'NE': 'Niger',
  'NF': 'Norfolk-sziget',
  'NG': 'Nigéria',
  'NI': 'Nicaragua',
  'NL': 'Hollandia',
  'NO': 'Norvégia',
  'NP': 'Nepál',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Új-Zéland',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'Francia Polinézia',
  'PG': 'Pápua Új-Guinea',
  'PH': 'Fülöp-szigetek',
  'PK': 'Pakisztán',
  'PL': 'Lengyelország',
  'PM': 'Saint-Pierre és Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palesztina',
  'PT': 'Portugália',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Románia',
  'RS': 'Szerbia',
  'RU': 'Oroszország',
  'RW': 'Ruanda',
  'SA': 'Szaúd-Arábia',
  'SB': 'Salamon-szigetek',
  'SC': 'Seychelle-szigetek',
  'SD': 'Szudán',
  'SE': 'Svédország',
  'SG': 'Singapore',
  'SH': 'Saint Helena-sziget',
  'SI': 'Szlovénia',
  'SJ': 'Svalbard és Jan Mayen',
  'SK': 'Szlovákia',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Szenegál',
  'SO': 'Szomália',
  'SR': 'Suriname',
  'SS': 'Dél-Szudán',
  'ST': 'São Tomé és Príncipe',
  'SV': 'Salvador',
  'SX': 'Sint Maarten',
  'SY': 'Szíria',
  'SZ': 'Szváziföld',
  'TC': 'Turks- és Caicos-szigetek',
  'TD': 'Csád',
  'TF': 'Francia déli és antarktiszi területek',
  'TG': 'Togo',
  'TH': 'Thaiföld',
  'TJ': 'Tádzsikisztán',
  'TK': 'Tokelau',
  'TL': 'Kelet-Timor',
  'TM': 'Türkmenisztán',
  'TN': 'Tunézia',
  'TO': 'Tonga',
  'TR': 'Törökország',
  'TT': 'Trinidad és Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan',
  'TZ': 'Tanzania',
  'UA': 'Ukrajna',
  'UG': 'Uganda',
  'UM': 'Egyesült Államok Virgin-szigetek',
  'US': 'Egyesült Államok',
  'UY': 'Uruguay',
  'UZ': 'Üzbegisztán',
  'VA': 'Vatikán',
  'VC': 'Saint Vincent és a Grenadine-szigetek',
  'VE': 'Venezuela',
  'VG': 'Brit Virgin-szigetek',
  'VI': 'Amerikai Virgin-szigetek',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis és Futuna',
  'WS': 'Samoa',
  'YE': 'Jemen',
  'YT': 'Mayotte',
  'ZA': 'Dél-Afrika',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Legközelebbi elérhető időpont',
  'nearestDateWithHour': '%{day} %{hour}',
  'tomorrow': 'holnap',
  'paymentMethod': 'Fizetési mód',
  'secureFormDescription': 'Fizetés gyors átutalással / hitelkártyával',
  'typeGeneratedBlikCode': 'Írja be a banki alkalmazásban generált egyszeri kódot.',
  'blik': 'BLIK',
  'typeBlikCode': 'Adja meg BLIK kódját',
  'blikCodePatternError': 'A kódnak 6 számjegyet kell tartalmaznia',
  'blikCode': 'BLIK kód',
  'simplifiedInvoice': 'Egyszerűsített',
  'fullInvoice': 'Teljes',
  'privateInvoice': 'Nevezett',
  'simplifiedInvoiceInformation': 'Az ügyfél egyszerűsített számlaként egy NIP-et tartalmazó nyugtát kap. Ez csak Lengyelországban bejegyzett cégek számára állítható ki. Külföldi cégek esetében válassza a teljes számlát.',
  'isNotPolishNip': 'A megadott TIN nem lengyel.',
  'polishNip': 'Lengyel szám TIN',
  'checkTheBankingApp': 'Ellenőrizze a banki alkalmazást',
  'confirmPaymentWithPin': 'Most erősítse meg a fizetést a PIN-kóddal a telefonján lévő alkalmazással.',
  'ticketsLoadedAfterConfirm': 'A nevezéseket a fizetés jóváhagyása után töltjük fel, és e-mailben küldjük el Önnek:',
  'paymentWithBlik': 'BLIK-kel fizetek',
  'cannontLoadPage': 'Az oldal betöltése nem sikerült',
  'refreshPage': 'Oldal frissítése',
  'isSoldOut': 'Elfogyott',
  'removeSelectedActivities': 'Kijelölt szolgáltatások törlése',
  'removeSelectedServicesQuestion': 'Kiválasztott szolgáltatások eltávolítása?',
  'allServicesWillBeRemoved': 'Minden szolgáltatás el lesz távolítva, és visszatér a kiválasztáshoz',
  'hourFull': 'Óra',
  'hasNoSeatsTest': 'Nincs hely',
  'pleaseSelectDate': 'Kérjük, válasszon egy dátumot',
  'pleaseSelectTime': 'Kérjük, válasszon időpontot',
  'selected': 'Kiválasztott',
  'term': 'Dátum',
  'price': 'Ár',
  'startDate': 'Kezdeti dátum',
  'endDate': 'Befejezés dátuma',
  'daysQuanity': 'Napok száma',
  'decrease': 'Csökkentés',
  'increase': 'Növelés',
  'minValueError': 'A minimális érték %{value}',
  'maxValueError': 'A maximális érték %{value}',
  'numberOfActivities': 'Szolgáltatások száma'
};

/* eslint-enable max-len */
