import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

type Props = {
  isSelect: boolean;
  children: React.ReactNode;
};

const TimePickerCellsWrapper = ({isSelect, children}: Props) => (
  isSelect ? (
    <Box> {children} </Box>
  ) : (
    <Grid container spacing={1}>
      {children}
    </Grid>
  )
);

export default TimePickerCellsWrapper;
