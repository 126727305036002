import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import Grid from '@mui/material/Grid';
import {TimePickerButton} from './TimePickerComponents';
import DefaultTimePickerCell from './DefaultTimePickerCell';
import TimePickerCellDetails from './TimePickerCellDetails';
import {getTimePickerItemDetails} from './utils';

const TimePickerOption = (
  {
    option,
    onChange,
    value,
    canShowItemsLeft,
    showPrices,
    buttonHeight,
    isWithAllAdditionalInformation,
    isWithAdditionalInformation
  }) => {
  const {date: selectedTime} = {...value};
  const timezone = useSelector(getFacilityTimezone);

  function handleChange() {
    return onChange(option);
  }

  const timePickerItemConfig = getTimePickerItemDetails(option, canShowItemsLeft, showPrices, timezone, selectedTime);
  const {isDisabled, isSelected} = timePickerItemConfig;

  return (
    <Grid item xs={3}>
      <TimePickerButton
        isSelected={isSelected}
        isDisabled={isDisabled}
        isCentered={!isWithAdditionalInformation}
        disableFocusRipple
        disableRipple
        onClick={handleChange}
        sx={{
          height: buttonHeight
        }}
      >
        {
          isWithAdditionalInformation ?
            <TimePickerCellDetails
              timePickerItemConfig={timePickerItemConfig}
              isWithAllAdditionalInformation={isWithAllAdditionalInformation}
            /> :
            <DefaultTimePickerCell
              timePickerItemConfig={timePickerItemConfig}
            />
        }
      </TimePickerButton>
    </Grid>
  );
};

TimePickerOption.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default TimePickerOption;
