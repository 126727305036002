import {IActivityVariantProcessed} from '@Consts/globals';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME} from './core';

// Clear the previous ecommerce object.
export const clearEcommerce = () => {
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {ecommerce: null}
  });
};

export const getTotalPrice = (variant: IActivityVariantProcessed, quantity: number) => {
  const price =
    variant?.cost?.price ??
    variant?.datesCostMin?.price ??
    0;

  return parseFloat((price * quantity).toFixed(2));
};
